import API from "../utils/API";
import {
  GET_ERRORS,
  CLEAR_ERRORS,
  ALL_REPORT_DATA,
  GET_REPORT_FAIL,
  CREATE_REPORT,
  CREATE_REPORT_FAIL,
  DELETE_REPORT,
  DELETE_REPORT_FAIL,
  GENERATE_NOW,
  GENERATE_NOW_FAIL,
} from "./types";
import { getTokenInfo } from "./authActions";
// action to handle errors
export const getErrors = (errorData) => {
  return {
    type: GET_ERRORS,
    payload: errorData,
  };
};
// Action to clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export const createReport = (data) => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to create report
    const response = await API.createReport(data, headers);
    // dispatch to reducer
    dispatch({
      type: CREATE_REPORT,
      payload: response,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch create report failed
    dispatch({
      type: CREATE_REPORT_FAIL,
    });
  }
};

export const getAllReport = () => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to fetch all reports
    const response = await API.getAllReport(headers);
    // dispatch to reducer
    dispatch({
      type: ALL_REPORT_DATA,
      payload: response.data,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch fetch fail
    dispatch({
      type: GET_REPORT_FAIL,
    });
  }
};

export const deleteReport = (data) => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to delete report
    const response = await API.deleteReport(data, headers);
    // dispatch to reducer
    dispatch({
      type: DELETE_REPORT,
      payload: response,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch delete report failed
    dispatch({
      type: DELETE_REPORT_FAIL,
    });
  }
};
export const GenerateNow = (data) => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to delete report
    const response = await API.generateNow(data, headers);
    // dispatch to reducer
    dispatch({
      type: GENERATE_NOW,
      payload: response,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch delete report failed
    dispatch({
      type: GENERATE_NOW_FAIL,
    });
  }
};
