import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import brandLogo from "../../images/SettleIt.svg";
import searchIocn from "../../images/serach-icon.svg";
import SideNav from "../../components/NavBar";
import Modal from "../../components/Modal";
import ListEntity from "./list";
import { deleteEntity, getAllEntities } from "../../actions/entity";
import { deleteFormula } from "../../actions/formula";
import ConfirmDialog from "../../components/ConfirmDialog";
import { EventEmitter } from "../../components/Common/EventEmitter";
import { getAllFormula } from "../../actions/formula";

export const Formula = () => {
  const [title, setTitle] = React.useState("Create Entity");
  const [confirmDialogShow, setConfirmDialogShow] = React.useState(false);
  const [locationsModal, setLocationsModal] = React.useState(false);
  const [formulaLocationid, setFormulaLocationid] = React.useState("");
  const [locations, setLocations] = React.useState([]);
  const [entityId, setEntityId] = React.useState("");
  const [assignModal, setAssignModal] = React.useState(false);
  const [formulaId, setFormulaId] = React.useState([]);
  const [allEntities, setAllEntities] = React.useState([]);
  const [channels, setChannels] = React.useState([]);
  const [editModalShow, setEditModalShow] = React.useState(false);
  const [updateData, setUpdateData] = React.useState(null);
  // const [updateData, setUpdateData] = React.useState(null);
  const [conditionModal, setConditionModal] = React.useState(false);

  //////// START GET ENTITIES /////////
  const { entities, locationsEntities } = useSelector((state) => state.entity);
  const { formulas, loading } = useSelector((state) => state.formula);
  const userData = useSelector((state) => state.auth.userData);
  const permissions = get(userData, "role.modules", []);
  const checkPermission = (moduleName) => {
    let obj = permissions.find((o) => o.name === moduleName);
    return get(obj, "permissions", { read: false });
  };
  const permission = checkPermission("Entities");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllEntities());
    dispatch(getAllFormula());
  }, [loading]);

  // useEffect(() => {
  //   setAllFormulas(entities);
  // });

  //////// END GET ENTITIES /////////

  /////////////// DELETE//////////////
  const handleDelete = (id) => {
    setConfirmDialogShow(true);
    setEntityId(id);
  };

  const onDelete = () => {
    dispatch(deleteFormula({ entityId })).then((d) => {
      EventEmitter.dispatch("message", "Success!");
      EventEmitter.dispatch("close");
      setConfirmDialogShow(false);
    });
  };
  /////////// END DELETE /////////
  const handleLocationModel = (locations, id) => {
    setLocationsModal(true);
    setLocations(locations);
    setFormulaLocationid(id);
  };
  const handleAssignFormula = (locations, formulaId, channelsList) => {
    setAssignModal(true);
    setLocations(locations);
    setFormulaId(formulaId);
    channelsList && setChannels(channelsList);
  };
  /////////////  SEARCH ////////////////

  const handleChange = (e) => {
    const value = e.target.value;
    onSearch(value);
  };
  const onSearch = (value) => {
    const newData = allEntities.filter((n) => {
      return new RegExp(value).test(n.title);
    });
    entities = newData;
    setAllEntities(newData);
  };
  // console.log("formulas", formulas);

  const handleEdit = (data, locationId) => {
    setEditModalShow(true);
    setTitle("Edit Formula");
    setUpdateData(data);
    // const title =
    //   row.documentType === "Entity" ? "Edit Entity" : "Edit Revenue Split";
    // setTitle(title);
    // setEditModalShow(true);
    // row.locationId = locationId;
  };

  ////////////////////////////////////////
  return (
    <>
      <section className="DashboardMainWrapper">
        <SideNav />
        <div className="DashboardWrapper">
          <div className="brandLogo">
            <img src={brandLogo} />
          </div>
          <div className="dashboardHeader">
            <h4>
              Formula<span></span>{" "}
            </h4>
            <div className="RightBlock">
              <div className="searchBlock">
                <img src={searchIocn} />
                <input
                  type="search"
                  placeholder="Search..."
                  onChange={handleChange}
                />{" "}
              </div>
              <Button
                type="btn"
                className="Bluebtn"
                style={{ maxWidth: "245px", alignItems: "left" }}
                onClick={() => {
                  setConditionModal(true);
                  setTitle("Create Formula");
                }}
              >
                Create Formula
              </Button>
              {conditionModal && (
                <Modal
                  name="createFormula"
                  open={conditionModal}
                  title={title}
                  entities={entities}
                  setClose={() => setConditionModal(false)}
                />
              )}
              {editModalShow && (
                <Modal
                  name="edit-formula"
                  open={editModalShow}
                  title={title}
                  entities={entities}
                  formulaData={updateData}
                  setClose={() => setEditModalShow(false)}
                />
              )}
            </div>
          </div>
          <div>
            <div className="table-responsive">
              {permission.read && (
                <ListEntity
                  handleLocationModel={handleLocationModel}
                  // handleEntityListModel={handleEntityListModel}
                  handleAssignFormula={handleAssignFormula}
                  handleDelete={handleDelete}
                  permission={permission}
                  // locationId={l._id}
                  handleEdit={handleEdit}
                  formulas={formulas}
                />
              )}
            </div>
          </div>
          {locationsModal && (
            <Modal
              name="user-locations"
              open={locationsModal}
              locations={locations}
              formulaId={formulaLocationid}
              permission={permission}
              setClose={() => setLocationsModal(false)}
            />
          )}
          {assignModal && (
            <Modal
              name="assign-locations"
              open={assignModal}
              locations={locations}
              formulaId={formulaId}
              channels={channels}
              setClose={() => setAssignModal(false)}
            />
          )}

          {confirmDialogShow && (
            <ConfirmDialog
              open={confirmDialogShow}
              // locations={locations}
              onDelete={onDelete}
              setClose={() => setConfirmDialogShow(false)}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default Formula;
