import API from "../utils/API";
import {
  GET_ERRORS,
  CLEAR_ERRORS,
  ALL_ROLES_DATA,
  GET_ROLES_FAIL,
  CREATE_ROLE,
  CREATE_ROLE_FAIL,
  EDIT_ROLE,
  EDIT_ROLE_FAIL,
  DELETE_ROLE,
  DELETE_ROLE_FAIL,
} from "./types";
import { getTokenInfo } from './authActions';
// action to handle errors
export const getErrors = (errorData) => {
  return {
    type: GET_ERRORS,
    payload: errorData,
  };
};
// Action to clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export const getAllRoles = () => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to fetch all roles
    const response = await API.getAllRoles(headers);
    // dispatch to reducer
    dispatch({
      type: ALL_ROLES_DATA,
      payload: response.data,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch fetch fail
    dispatch({
      type: GET_ROLES_FAIL,
    });
  }
};

export const createRole = (data) => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to create role
    const response = await API.createRole(data, headers);
    // dispatch to reducer
    dispatch({
      type: CREATE_ROLE,
      payload: response,
    });
  } catch (err) {
    console.log('CREATE_ROLE_FAIL >>>>>', err)
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch create role failed
    dispatch({
      type: CREATE_ROLE_FAIL,
    });
  }
};

export const editRole = (data) => async (dispatch) => {
  try {
    // send request to server side to edit role
    const response = await API.editRole(data);
    // dispatch to reducer
    dispatch({
      type: EDIT_ROLE,
      payload: response,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch edit role failed
    dispatch({
      type: EDIT_ROLE_FAIL,
    });
  }
};

export const deleteRole = (data) => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to delete role
    const response = await API.deleteRole(data, headers);
    // dispatch to reducer
    dispatch({
      type: DELETE_ROLE,
      payload: response,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch delete role failed
    dispatch({
      type: DELETE_ROLE_FAIL,
    });
  }
};