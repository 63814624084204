import React from "react";
import { get } from "lodash";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import brandLogo from "../../images/SettleIt.svg";
import Button from "@mui/material/Button";
import SideNav from "../../components/NavBar";
import Typography from "@mui/material/Typography";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 500,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

export const Docs = () => {
  const [open, setOpen] = React.useState(false);
  const [copied, setCopied] = React.useState(false);

  const userData = useSelector((state) => state.auth.userData);
  const secretKey = get(userData, "secretKey", "");

  const handleClose = () => {
    setCopied(false);
    setOpen(false);
  };

  return (
    <>
      <section className="DashboardMainWrapper">
        <SideNav />
        <div className="DashboardWrapper">
          <div className="brandLogo">
            <img alt="" src={brandLogo} />
          </div>
          <div className="dashboardHeader">
            <h4>API Document</h4>
            <div className="RightBlock">
              <Button
                type="btn"
                className="Bluebtn"
                onClick={() => setOpen(true)}
              >
                Get Api Key
              </Button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="Api-key-modal"
              >
                <Box sx={style}>
                  <div className="">
                    <Typography
                      id=""
                      variant="h6"
                      component="h2"
                      className="mb-2"
                    >
                      Standard keys
                    </Typography>
                    <p4>
                      These keys will allow you to authenticate API requests
                    </p4>
                    <br />
                    <hr />
                    <p>
                      {" "}
                      Secret key: {secretKey}{" "}
                      <CopyToClipboard
                        text={secretKey}
                        onCopy={() => setCopied(true)}
                      >
                        <span>
                          <ContentCopyIcon className="green-theme"/>
                        </span>
                      </CopyToClipboard>
                    </p>

                    {copied ? (
                      <span style={{ color: "#00FF00" }}>Copied.</span>
                    ) : (
                      ""
                    )}
                  </div>
                </Box>
              </Modal>
            </div>
          </div>
          <hr />
          <div>
            <iframe
              style={{ width: "100%", height: "100vh" }}
              src="https://settleit.tech/api-docs"
              title="description"
            ></iframe>
          </div>
        </div>
      </section>
    </>
  );
};

export default Docs;
