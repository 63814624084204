import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import { deleteLocation, getAllLocations } from "../../actions/locations";
import { EventEmitter } from "../../components/Common/EventEmitter";
import { deleteFormulaLocation } from "../../actions/formula";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const UserLocations = (props) => {
  const dispatch = useDispatch();
  console.log({ locationprops: props });
  const { locations, formulaId, permission } = props;
  // const locations = [];

  const deleteById = (locationId, formulaId) => {
    console.log(locationId, formulaId);
    dispatch(deleteFormulaLocation({ formulaId, locationId })).then((d) => {
      EventEmitter.dispatch("message", "Success!");
      EventEmitter.dispatch("close");
      // setConfirmDialogShow(false);
    });
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>S.No.</StyledTableCell>
            <StyledTableCell>Address</StyledTableCell>
            {permission && <StyledTableCell>Actions</StyledTableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {locations.map((row, i) => (
            <StyledTableRow key={row._id}>
              <StyledTableCell component="th" scope="row">
                {i + 1}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {row.locationAddress}
              </StyledTableCell>
              {permission?.delete && (
                <StyledTableCell>
                  <Button onClick={() => deleteById(row._id, formulaId)}>
                    <DeleteIcon />
                  </Button>
                </StyledTableCell>
              )}
              {/* <StyledTableCell align="right">
                <Button onClick={() => deleteLocationById(row._id)}>
                  <DeleteIcon />
                </Button>
              </StyledTableCell> */}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserLocations;
