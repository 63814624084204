import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useDispatch } from "react-redux";
import { deleteLocation, getAllLocations } from "../../actions/locations";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function DeleteModal(props) {
  const [open, setOpen] = React.useState(props.openModal);
  const dispatch = useDispatch();
  const deleteUser = () => {
    dispatch(deleteLocation({ _id: props.locationId }));
    setTimeout(() => {
      dispatch(getAllLocations());
      handleClose();
    }, 3000);
  };
  const handleClose = () => {
    setOpen(false);
    props.setModal(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Are you sure?"}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={() => deleteUser()}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
