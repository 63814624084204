import React, { useEffect } from "react";
import { startCase } from "lodash";
// import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import EditIcon from "@mui/icons-material/Edit";

import Modal from "../../components/Modal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));

export const ListEntity = (props) => {
  const permission = props.permission;
// console.log(props);
  const deleteById = (id) => {
    props.handleDelete(id);
  };
  //////////////////////////////////

  const newEntities = props.entities.filter((n) => {
    if (props.type === "RevenueSplit") {
      return (
        (n.documentType === props.type || n.documentType === "formula") &&
        n.title !== "Total"
      );
    } else {
      return n.documentType === props.type || n.title === "Total";
    }
  });
  ////////////////////////////////

  return props.entities.length === 0 ? (
    <div className="noRecordMessage">No records found.</div>
  ) : (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>S.No.</StyledTableCell>
            <StyledTableCell>Title</StyledTableCell>
            <StyledTableCell>Value</StyledTableCell>
            <StyledTableCell>Entity/Split</StyledTableCell>
            {!props.appliesOn && (
              <StyledTableCell align="right">Action</StyledTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.entities.map((row, i) => (
            <StyledTableRow key={row._id}>
              <StyledTableCell component="th" scope="row">
                {i + 1}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {row.title}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {row.formula !== "" && row.formula !== undefined ? (
                  <Chip
                    label={`${row.formula}`}
                    color="primary"
                    variant="outlined"
                  />
                ) : row.title === "Baserate" || row.title === "Total" ? (
                  <Chip label="?" color="error" variant="outlined" />
                ) : row.type === "Percentage" ? (
                  // <p className="bluestatus">{row.value}%</p>
                  <Chip
                    label={`${row.value}%`}
                    color="primary"
                    variant="outlined"
                  />
                ) : (
                  <Chip
                    label={`$${row.value}`}
                    color="success"
                    variant="outlined"
                  />
                  // <p className="greenstatus">${row.value}</p>
                )}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {row.documentType === "Entity" ? "Entity" : "Split"}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" align="right">
                {row.title === "Baserate" ||
                  row.title === "Merchant Fee" ||
                  row.title === "Total" ? (
                  "-"
                ) : (
                  <>
                    {permission.edit && (
                      <Button
                        onClick={() => props.handleEdit(row, props.locationId)}
                      >
                        <EditIcon />
                      </Button>
                    )}
                    {permission.delete && (
                      <Button onClick={() => deleteById(row._id)}>
                        <DeleteIcon color="error" />
                      </Button>
                    )}
                  </>
                )}
              </StyledTableCell>
              {/* {!props.appliesOn && row.appliesOn.length > 0 && (
                <StyledTableCell>
                  (
                  {row.appliesOn.map((a, i) => {
                    return i < row.appliesOn.length - 1 ? (
                      <>
                        <Chip key={i} label={a.title} variant="outlined" /> +
                      </>
                    ) : (
                      <>
                        <Chip key={i} label={a.title} variant="outlined" />
                      </>
                    );
                  })}
                  ) % {row.value}
                </StyledTableCell>
              )}
              {!props.appliesOn && row.appliesOn.length === 0 && (
                <StyledTableCell>-</StyledTableCell>
              )} */}
              {/* {!props.appliesOn && (
                <StyledTableCell>
                  {row.title === "Baserate" || row.title === "Total" ? (
                    "-"
                  ) : (
                    <Button onClick={() => deleteById(row._id)}>
                      <DeleteIcon color="error" />
                    </Button>
                  )}
                </StyledTableCell>
              )} */}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListEntity;
