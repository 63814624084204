import React, { useEffect } from "react";
import * as yup from "yup";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";

import MenuItem from "@mui/material/MenuItem";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";

import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Chip } from "@mui/material";
import { EventEmitter } from "../../components/Common/EventEmitter";

/////////////////ACTIONS///////////////////
import { assignLocations } from "../../actions/formula";
import { getAllLocations } from "../../actions/locations";

import { size } from "lodash";

////////////////////
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AssignLocations(props) {
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [locationsData, setLocationsData] = React.useState([]);
  const [channelsData, setChannelsData] = React.useState([]);
  const [selectedChannels, setSelectedChannels] = React.useState(
    props.channels
  );
  const [open, setOpen] = React.useState(false);

  const { locations } = useSelector((state) => state.location);
  useEffect(() => {
    dispatch(getAllLocations());
    if (props.locations.length > 0) {
      const locations = props.locations.map((n) => {
        if (n.channels) {
          setChannelsData((prev) => [...prev, ...n.channels]);
        }
        return n._id;
      });
      setLocationsData(locations);
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (locationsData.length <= 0) {
      setErrorMessage("All fields are mandatory");
    } else {
      const body = {
        locations: locationsData,
        channelsData: selectedChannels,
        formulaId: props.formulaId,
      };

      setIsSubmitting(true);
      dispatch(assignLocations(body)).then((d) => {
        EventEmitter.dispatch("message", "Success!");
        EventEmitter.dispatch("close");
      });
    }
  };
  //   });
  const findAddress = (selected) => {
    let locationAddressArray = [];
    const l = locations.filter((l) => selected.includes(l._id));
    l.map((ls) => locationAddressArray.push(ls.locationAddress));
    return l;
  };

  const handleChangeLocations = (event) => {
    const {
      target: { value },
    } = event;

    let channel = value?.map((el) => {
      const location = locations.filter((e) => e._id === el);
      return location[0].channels ? location[0].channels : [];
    });
    if (channel.length) {
      channel = channel.flat().reduce((acc, currentValue) => {
        if (!acc.includes(currentValue)) {
          acc.push(currentValue);
        }
        return acc;
      }, []);
      setChannelsData(channel);
      setOpen(false);
    }

    setLocationsData(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeChannels = (event) => {
    const {
      target: { value },
    } = event;
    // setSelectedChannels(typeof value === "string" ? value.split(",") : value);
    setSelectedChannels(value);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleDelete = (name) =>
    setLocationsData((prev) => prev.filter((loc) => loc !== name._id));

  const sliceString = (str) =>
    str.length > 16 ? `${str.slice(0, 15)}... ` : str;

  return (
    <>
      <div className="create-entity-modal" style={{ width: "500px" }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Assign Locations
        </Typography>

        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "100%" },
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="demo-multiple-checkbox-label">Locations</InputLabel>
            <Select
              // id="filled-select-currency"
              multiple
              label="Select Locations"
              variant="filled"
              name="locations"
              value={locationsData}
              onChange={handleChangeLocations}
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    maxHeight: 150,
                    overflow: "auto",
                    scrollbarWidth: "none",
                  }}
                >
                  {findAddress(selected).map((value) => (
                    <Chip
                      key={value._id}
                      label={sliceString(value.locationAddress)}
                      onDelete={() => handleDelete(value)}
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
              input={<OutlinedInput label="Locations" />}
              // error={formik.touched.locations && Boolean(formik.errors.locations)}
              // helperText={formik.touched.locations && formik.errors.locations}
            >
              {locations.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  <Checkbox checked={locationsData.indexOf(option._id) > -1} />
                  <ListItemText primary={option.locationAddress} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {channelsData.length || selectedChannels.length ? (
            <FormControl fullWidth>
              <InputLabel id="demo-multiple-checkbox-label">
                Channels
              </InputLabel>
              <Select
                // id="filled-select-currency"
                multiple
                label="Select Channels"
                variant="filled"
                name="Channels"
                value={selectedChannels}
                onChange={handleChangeChannels}
                renderValue={(selected) => selectedChannels.join(", ")}
                MenuProps={MenuProps}
                input={<OutlinedInput label="Channels" />}
                // error={formik.touched.locations && Boolean(formik.errors.locations)}
                // helperText={formik.touched.locations && formik.errors.locations}
              >
                {channelsData.map((option, i) => (
                  <MenuItem key={i} value={option}>
                    <Checkbox checked={selectedChannels.includes(option)} />
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            ""
          )}
          <Typography style={{ color: "red" }} variant="h6">
            {errorMessage}
          </Typography>

          <div className="text-center mt-2">
            <Button
              className="Bluebtn"
              variant="contained"
              fullWidth
              type="submit"
              disabled={isSubmitting}
              onClick={onSubmit}
            >
              Proceed
            </Button>
          </div>
        </Box>
      </div>
    </>
  );
}
