import {
  // GET_ERRORS,
  // CLEAR_ERRORS,
  ALL_LOCATIONS_DATA,
  DELETE_LOCATION,
  CLEAR_LOCATIONS,
  CREATE_LOCATION,
  ASSIGN_SPLITS,
  DELETE_LOCATION_SPLIT,
  EDIT_LOCATION
} from "../actions/types";

const initialState = {
  locations: [],
  loading: false,
  locationRes: null,
};
// reducer to handle auth Actions
export default (state = initialState, action) => {
  switch (action.type) {
    case ALL_LOCATIONS_DATA:
      return {
        ...state,
        loading: false,
        locations: action.payload.data,
      };
    case CREATE_LOCATION:
      return {
        ...state,
        loading: action.payload.data.success,
        message: action.payload.data.message,
        locationRes: action.payload.data,
      };
    case DELETE_LOCATION:
      return {
        ...state,
        loading: action.payload.data.success,
        message: action.payload.data.message,
      };
    case EDIT_LOCATION:
      return {
        ...state,
        loading: action.payload.data.success,
        message: action.payload.data.message,
      };
      case ASSIGN_SPLITS:
        return {
          ...state,
          loading: action.payload.data.success,
          message: action.payload.data.message,
        };
      case DELETE_LOCATION_SPLIT:
        return {
          ...state,
          loading: action.payload.data.success,
          message: action.payload.data.message,
        };
      case CLEAR_LOCATIONS:
        return {
          ...state,
          locationRes: null,
        };
      default:
        return state;
  }
};
