import React from "react";
import * as yup from 'yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from 'formik';
import { loginUser } from "../../actions/authActions";

import rightArrow from "../../images/rightarrow.svg";
const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(5, 'Password should be of minimum 5 characters length')
    .required('Password is required'),
});

export const Login = () => {
  // access to the isAuthenticated property from the auth reducer state
  const { isAuthenticated } = useSelector((state) => state.auth);
   const { message}  = useSelector((state) => state.errors);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(loginUser(values));
    },
  });
  return (
    <>
      <section className="LoginPage">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6">
              <div className="leftBlock">
                <div className="leftBlockText">
                  <h6 className="green-theme">Welcome to</h6>
                  <h1 className="green-theme">Settle It</h1>
                </div>
                <form onSubmit={formik.handleSubmit}>
                  <TextField
                    fullWidth
                    id="email"
                    className="mb-4"
                    name="email"
                    label="Email"
                    placeholder="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                  <TextField
                    fullWidth
                    id="password"
                    name="password"
                    className="mb-4"
                    label="Password"
                    placeholder="Password"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                  />
                  <Button className="btn" variant="contained" fullWidth type="submit">
                    Login <img src={rightArrow} />
                  </Button>
                </form>
                 {message && <div className='error'>{message}</div>}
              </div>
            
            </div>
            <div className="col-lg-5">
              <h6 className="green-theme">“The advance of technology is based on making it fit in so that you don't really even notice it, so it's part of everyday life.”</h6>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
