import { ALL_HISTORY_DATA, ALL_HISTORY_DATA_SUCCESS } from "../actions/types";
const initialState = {
  loading: false,
  message: "",
  historyData: [],
};
// reducer to handle auth Actions
export default (state = initialState, action) => {
  switch (action.type) {
    case ALL_HISTORY_DATA:
      return {
        ...state,
        loading: false,
        historyData: action.payload.data.data,
      };
    case ALL_HISTORY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        historyData: action.payload.data.data,
      };

    default:
      return state;
  }
};
