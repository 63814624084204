import React, { useEffect } from "react";
import Button from "@mui/material/Button";

import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import brandLogo from "../../images/SettleIt.svg";
import searchIocn from "../../images/serach-icon.svg";
import SideNav from "../../components/NavBar";
import Modal from "../../components/Modal";
import ListEntity from "./list";
import { deleteEntity, getAllEntities } from "../../actions/entity";
import ConfirmDialog from "../../components/ConfirmDialog";
import { EventEmitter } from "../../components/Common/EventEmitter";

export const Entity = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [conditionModal, setConditionModal] = React.useState(false);

  const [editModalShow, setEditModalShow] = React.useState(false);

  const [title, setTitle] = React.useState("Create Entity");
  const [confirmDialogShow, setConfirmDialogShow] = React.useState(false);
  const [locationsModal, setLocationsModal] = React.useState(false);
  const [locations, setLocations] = React.useState([]);
  const [entityId, setEntityId] = React.useState("");
  const [entityListModal, setEntityListModal] = React.useState(false);
  const [appliesOn, setAppliesOn] = React.useState([]);
  const [allEntities, setAllEntities] = React.useState([]);
  const [updateData, setUpdateData] = React.useState(null);
  // const [updateData, setUpdateData] = React.useState(null);

  //////// START GET ENTITIES /////////
  const { loading, entities } = useSelector((state) => state.entity);

  const userData = useSelector((state) => state.auth.userData);
  const permissions = get(userData, "role.modules", []);
  const checkPermission = (moduleName) => {
    let obj = permissions.find((o) => o.name === moduleName);
    return get(obj, "permissions", { read: false });
  };
  const permission = checkPermission("Entities");

  const dispatch = useDispatch();
  useEffect(() => {
    setAllEntities(entities);
    dispatch(getAllEntities());
    // dispatch(getAllEntitiesLocationWise());
  }, [loading]);

  useEffect(() => {
    setAllEntities(entities);
  });

  //////// END GET ENTITIES /////////

  /////////////// DELETE//////////////
  const handleDelete = (id) => {
    setConfirmDialogShow(true);
    setEntityId(id);
  };

  const onDelete = () => {
    dispatch(deleteEntity({ entityId })).then((d) => {
      EventEmitter.dispatch("message", "Success!");
      EventEmitter.dispatch("close");
      setConfirmDialogShow(false);
    });
  };
  /////////// END DELETE /////////
  const handleLocationModel = (locations) => {
    setLocationsModal(true);
    setLocations(locations);
  };
  const handleEntityListModel = (appliesOn) => {
    setEntityListModal(true);
    setAppliesOn(appliesOn);
  };
  /////////////  SEARCH ////////////////

  const handleChange = (e) => {
    const value = e.target.value;
    onSearch(value);
  };
  const onSearch = (value) => {
    const newData = allEntities.filter((n) => {
      return new RegExp(value).test(n.title);
    });
    entities = newData;
    setAllEntities(newData);
  };
  const handleEdit = (row, locationId) => {
    // console.log(row);
    // console.log(locationId);
    const title =
      row.documentType === "Entity" ? "Edit Entity" : "Edit Revenue Split";
    setTitle(title);
    setEditModalShow(true);
    row.locationId = locationId;
    setUpdateData(row);
  };

  ////////////////////////////////////////
  return (
    <>
      <section className="DashboardMainWrapper">
        <SideNav />
        <div className="DashboardWrapper">
          <div className="brandLogo">
            <img src={brandLogo} />
          </div>
          <div className="dashboardHeader">
            <h4>
              Entities<span></span>
            </h4>
            <div className="RightBlock">
              <div className="searchBlock">
                <img src={searchIocn} />
                <input
                  type="search"
                  placeholder="Search..."
                  onChange={handleChange}
                />
              </div>
              {permission.write && (
                <>
                  <Button
                    type="btn"
                    className="Bluebtn"
                    onClick={() => {
                      setModalShow(true);
                      setTitle("Create Entity");
                    }}
                  >
                    Add Entity
                  </Button>
                  <Button
                    type="btn"
                    className="Bluebtn"
                    onClick={() => {
                      setModalShow(true);
                      setTitle("Revenue Split");
                    }}
                  >
                    Add Split
                  </Button>
                </>
              )}
              {modalShow && (
                <Modal
                  name="entity"
                  open={modalShow}
                  title={title}
                  entities={entities}
                  setClose={() => setModalShow(false)}
                />
              )}
              {/* {conditionModal && (
                <Modal
                  name="createFormula"
                  open={conditionModal}
                  title={title}
                  entities={entities}
                  setClose={() => setModalShow(false)}
                />
              )} */}
              {editModalShow && (
                <Modal
                  name="edit-entity"
                  open={editModalShow}
                  title={title}
                  entities={entities}
                  entityData={updateData}
                  setClose={() => {
                    setModalShow(false)
                    setEditModalShow(false)}}
                />
              )}
            </div>
          </div>
          {permission.read && (
            <ListEntity
              handleLocationModel={handleLocationModel}
              handleEntityListModel={handleEntityListModel}
              handleDelete={handleDelete}
              permission={permission}
              // locationId={l._id}
              handleEdit={handleEdit}
              type="Entity"
              entities={entities}
            />
          )}
          {/* <div>
            {locationsEntities.length === 0 ? (
              <div className="noRecordMessage">No records found.</div>
            ) : (
              locationsEntities.length > 0 &&
              locationsEntities.map((l, i) => {
                return (
                  <div key={l._id}>
                    <Box sx={{ minWidth: 275, margin: "10px" }}>
                      <Card variant="outlined">
                        <CardContent>
                          <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            <Chip label={i + 1} color="primary" />
                            <Chip
                              label={l.locationAddress}
                              color="primary"
                              variant="outlined"
                            />
                          </Typography>
                        </CardContent>

                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Item>
                              <Chip
                                label={"Entity"}
                                color="primary"
                                variant="outlined"
                              />{" "}
                              <hr />
                              <div className="table-responsive">
                                {permission.read && (
                                  <ListEntity
                                    handleLocationModel={handleLocationModel}
                                    handleEntityListModel={
                                      handleEntityListModel
                                    }
                                    handleDelete={handleDelete}
                                    permission={permission}
                                    locationId={l._id}
                                    handleEdit={handleEdit}
                                    type="Entity"
                                    entities={l.entities}
                                  />
                                )}
                              </div>
                            </Item>
                          </Grid>
                          <Grid item xs={6}>
                            <Item>
                              {" "}
                              <Chip
                                label={"Split"}
                                color="primary"
                                variant="outlined"
                              />
                              <hr />
                              <div className="table-responsive">
                                {permission.read && (
                                  <ListEntity
                                    handleLocationModel={handleLocationModel}
                                    handleEntityListModel={
                                      handleEntityListModel
                                    }
                                    handleDelete={handleDelete}
                                    permission={permission}
                                    locationId={l._id}
                                    handleEdit={handleEdit}
                                    type="RevenueSplit"
                                    entities={l.entities}
                                  />
                                )}
                              </div>
                            </Item>
                          </Grid>
                        </Grid>
                      </Card>
                    </Box>
                  </div>
                );
              })
            )}
          </div> */}
          {locationsModal && (
            <Modal
              name="user-locations"
              open={locationsModal}
              locations={locations}
              setClose={() => setLocationsModal(false)}
            />
          )}
          {entityListModal && (
            <Modal
              name="entity-list"
              open={entityListModal}
              entities={appliesOn}
              appliesOn={true}
              setClose={() => setEntityListModal(false)}
            />
          )}
          {confirmDialogShow && (
            <ConfirmDialog
              open={confirmDialogShow}
              // locations={locations}
              onDelete={onDelete}
              setClose={() => setConfirmDialogShow(false)}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default Entity;
