import {
  CREATE_FROMULA,
  ASSIGN_FROMULA,
  FORMULA_DATA,
  UPDATE_FROMULA,
  DELETE_FORMULA,
  DELETE_FORMULA_LOCATION
} from "../actions/types";
const initialState = {
  formulas: [],
  loading: false,
  message: "",
};
// reducer to handle auth Actions
export default (state = initialState, action) => {
  switch (action.type) {
    case FORMULA_DATA:
      return {
        ...state,
        loading: false,
        formulas: action.payload.data,
      };

    //   case DELETE_ENTITY:
    //     return {
    //       ...state,
    //       loading: action.payload.data.success,
    //       message: action.payload.data.message,
    //     };

    case CREATE_FROMULA:
      return {
        ...state,
        loading: action.payload.data.success,
        message: action.payload.data.message,
      };
      case UPDATE_FROMULA:
        return {
          ...state,
          loading: action.payload.data.success,
          message: action.payload.data.message,
        };
      case DELETE_FORMULA:
        return {
          ...state,
          loading: action.payload.data.success,
          message: action.payload.data.message,
        };
      case ASSIGN_FROMULA:
        return {
          ...state,
          loading: action.payload.data.success,
          message: action.payload.data.message,
        };
        case DELETE_FORMULA_LOCATION:
          return {
            ...state,
            loading: action.payload.data.success,
            message: action.payload.data.message,
          };
    //   case ENTITIES_DATA:
    //     return {
    //       ...state,
    //       locationsEntities: action.payload.data,
    //       loading: false,
    //     };

    default:
      return state;
  }
};
