import {
  ALL_USERS_DATA,
  DELETE_USERS,
  UPDATE_USERS,
  CLEAR_USER,
} from "../actions/types";

const initialState = {
  users: [],
  loading: false,
  message: "",
  updateRes: null,
};
// reducer to handle auth Actions
export default (state = initialState, action) => {
  switch (action.type) {
    case ALL_USERS_DATA:
      return {
        ...state,
        loading: false,
        users: action.payload.data,
      };

    case DELETE_USERS:
      return {
        ...state,
        loading: action.payload.data.success,
        message: action.payload.data.message,
      };
    case UPDATE_USERS:
      return {
        ...state,
        // loading: action.payload.data.success,
        // message: action.payload.data.message,
        updateRes: action.payload,
      };
    case CLEAR_USER:
      return {
        ...state,
        updateRes: null,
      };
    default:
      return state;
  }
};
