import React, { useState, useEffect } from "react";
import * as yup from "yup";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";

import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Chip } from "@mui/material";
import { EventEmitter } from "../../components/Common/EventEmitter";

/////////////////ACTIONS///////////////////
import { getAllRoles } from "../../actions/role";
import { registerUser, clearAuth } from "../../actions/authActions";
import { getAllLocations } from "../../actions/locations";
import {
  getAllUsers,
  clearErrors,
  updateUser,
  clearUser,
} from "../../actions/userAction";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Schema = Yup.object().shape({
  fullName: Yup.string()
    .min(2, "Too Short!")
    // .max(100, "Too Long!")
    .required("Required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  mobile: yup.number("Enter your mobile").optional("Mobile is required"),
  locations: Yup.array()
    .required("Array is required")
    .min(1, "Array must have at least one item"),
  role: yup.string("Select user role").required("Role is required"),
});

export default function CreateUser(props) {
  const dispatch = useDispatch();
  //////////GET ROLES AND LOCATIONS///////////
  const { roles } = useSelector((state) => state.role);
  const { locations } = useSelector((state) => state.location);
  const [locationsData, setLocationsData] = React.useState([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // const userData = useSelector((state) => state.auth.userData);
  const [open, setOpen] = React.useState(false);

  const { user } = props;
  let locationsId = [];
  if (user) {
    locationsId = user.locations.map((n) => {
      return n._id;
    });
  }
  const formik = useFormik({
    initialValues: {
      email: user !== null ? user.email : "",
      fullName: user !== null ? user.fullName : "",
      mobile: user !== null ? user.mobile : "",
      role: user !== null ? user.role._id : "",
      locations: user !== null ? locationsId : [],
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      setIsSubmitting(true);
      // values.locations = locationsData;

      let req = null;
      if (user) {
        values._id = user._id;
        req = updateUser(values);
      } else {
        req = registerUser(values);
      }
      dispatch(req);
    },
  });

  useEffect(() => {
    dispatch(getAllRoles());
    dispatch(getAllLocations());
    if (user) {
      const locations = user.locations.map((n) => {
        return n._id;
      });
      setLocationsData(locations);
    }
  }, []);
  ///////////////////////////////
  const error = useSelector((state) => state.errors);
  const { registerRes } = useSelector((state) => state.auth);
  const { updateRes } = useSelector((state) => state.user);

  useEffect(() => {
    if (error.message) {
      setErrorMessage(error.message);
      setIsSubmitting(false);
      dispatch(clearErrors());
    }
    if (registerRes && registerRes.success) {
      props.setClose(false);
      EventEmitter.dispatch("message", "Success!");
      EventEmitter.dispatch("close");
      dispatch(clearAuth());
      dispatch(getAllUsers());
    }
    if (registerRes && !registerRes.success) {
      setIsSubmitting(false);
      setErrorMessage(registerRes.message);
      // dispatch(clearErrors());
    }
    if (updateRes && updateRes.success) {
      props.setClose(false);
      EventEmitter.dispatch("message", "Success!");
      EventEmitter.dispatch("close");
      dispatch(clearUser());
      dispatch(getAllUsers());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, registerRes, updateRes]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setLocationsData(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const findAddress = (selected) => {
    let locationAddressArray = [];
    const l = locations.filter((l) => selected.includes(l._id));
    l.map((ls) => locationAddressArray.push(ls.locationAddress));
    return l;
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleDelete = (name) => {
    const locations = formik.values.locations.filter((loc) => loc !== name._id);
    formik.setFieldValue("locations", locations);
  };

  const sliceString = (str) =>
    str.length > 16 ? `${str.slice(0, 15)}... ` : str;

  return (
    <>
      <div className="add-user-modal">
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {props.title}
        </Typography>

        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          // noValidate
          // autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <TextField
            variant="outlined"
            name="fullName"
            label="Name"
            type="text"
            value={formik.values.fullName}
            onChange={formik.handleChange}
            error={formik.touched.fullName && Boolean(formik.errors.fullName)}
            helperText={formik.touched.fullName && formik.errors.fullName}
          />
          <TextField
            variant="outlined"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <TextField
            variant="outlined"
            name="mobile"
            label="Mobile (Optional)"
            type="mobile"
            value={formik.values.mobile}
            onChange={formik.handleChange}
            error={formik.touched.mobile && Boolean(formik.errors.mobile)}
            helperText={formik.touched.mobile && formik.errors.mobile}
          />
          <TextField
            // id="filled-select-currency"
            select
            label="Select Role"
            variant="outlined"
            name="role"
            value={formik.values.role}
            onChange={formik.handleChange}
            error={formik.touched.role && Boolean(formik.errors.role)}
            helperText={formik.touched.role && formik.errors.role}
          >
            {roles.map((option) => (
              <MenuItem key={option._id} value={option._id}>
                {option.title}
                {/* {option._id} */}
              </MenuItem>
            ))}
          </TextField>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-checkbox-label">Locations</InputLabel>
            <Select
              // id="filled-select-currency"
              multiple
              label="Select Locations"
              variant="filled"
              name="locations"
              value={formik.values.locations}
              onChange={formik.handleChange}
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    maxHeight: 150,
                    overflow: "auto",
                    scrollbarWidth: "none",
                  }}
                >
                  {findAddress(selected).map((value) => (
                    <Chip
                      key={value._id}
                      label={sliceString(value.locationAddress)}
                      onDelete={() => handleDelete(value)}
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
              input={<OutlinedInput label="Locations" />}
              error={
                formik.touched.locations && Boolean(formik.errors.locations)
              }
              helperText={formik.touched.locations && formik.errors.locations}
            >
              {locations.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  <Checkbox
                    checked={formik.values.locations.indexOf(option._id) > -1}
                  />
                  <ListItemText primary={option.locationAddress} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Typography style={{ color: "red" }} variant="h6">
            {errorMessage}
          </Typography>
          <div className="text-center mt-2">
            <Button
              className="Bluebtn"
              variant="contained"
              fullWidth
              type="submit"
              disabled={isSubmitting}
            >
              Proceed
            </Button>
          </div>
        </Box>
      </div>
    </>
  );
}
