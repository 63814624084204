import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import { utils, write } from "xlsx";

/////// MUI COMPONENT//////////

import FileSaver from "file-saver";
import { LoadingButton } from "@mui/lab";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import UploadFileIcon from "@mui/icons-material/UploadFile";
// import CircularProgressWithLabel from "../../components/CircularProgressWithLabel";
import { Box, Button, CardContent } from "@mui/material";

///////////////////////////
import Upload from "./upload";
import { useDispatch, useSelector } from "react-redux";

// import Select from "react-select";
import brandLogo from "../../images/SettleIt.svg";
import SideNav from "../../components/NavBar";
import { fileUpload, clearErrors } from "../../actions/reconciliation";
import SuccessModal from "../../utils/successModal";
/////////// SCHEMA/////////

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

////////////////////////////
export const Reconciliation = () => {
  const dispatch = useDispatch();
  // const [progress, setProgress] = React.useState(0);
  // const [totalProgressValue, setTotalProgressValue] = React.useState(0);
  const [downloadButton, setDownloadButton] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [reconcileData, setReconcileData] = React.useState([]);
  const [historyId, setHistoryId] = React.useState("");

  ////////////////////////////////////////////

  const error = useSelector((state) => state.errors);
  // const { reconciledData } = useSelector((state) => state.reconciliation);
  const { fileUploadRes } = useSelector((state) => state.reconciliation);

  useEffect(() => {
    if (error.message) {
      // setErrorMessage(error.message);
      dispatch(clearErrors());
      setLoading(false);
    }
    if (fileUploadRes && fileUploadRes.success) {
      setHistoryId(fileUploadRes.historyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, fileUploadRes]);

  /////////////////////////////////////////

  const onDownload = async () => {
    // const res = get(fileRes, "data.data", []);
    const dataItems = reconcileData;
    const ws = XLSX.utils.json_to_sheet(dataItems);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });

    const fileName = `Reconciliation-Reports_${new Date()}`;
    // const file = new File([excelBuffer], fileName, { type: "xlsx" });
    // var f = new File([""], "filename.txt", {
    //   type: "text/plain",
    //   lastModified: date,
    // });

    FileSaver.saveAs(data, fileName);
    handleHistoryRecord(reconcileData);
  };
  // console.log("totalProgressValue=========", totalProgressValue);
  // console.log("progress=========", progress);

  const handleHistoryRecord = (jsonData) => {
    console.log("jsonData=========", jsonData);

    const worksheet = utils.json_to_sheet(jsonData);

    // Create a workbook and add the worksheet
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    // Write the workbook to a binary string
    const excelData = write(workbook, { type: "binary" });

    // Create a file object from the binary string
    const buf = new ArrayBuffer(excelData.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < excelData.length; i++) {
      view[i] = excelData.charCodeAt(i) & 0xff;
    }
    const file = new File([buf], "data.xlsx", {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    console.log("file=========", file);

    let formData = new FormData(); //formdata object
    formData.append("historyId", historyId); // append the values with key, value pair
    formData.append("file", file);
    dispatch(fileUpload(formData));
  };
  const handleDownload = (data) => {
    if (data.length > 0) {
      setDownloadButton(true);
      setReconcileData(data);
    }
  };

  return (
    <>
      <section className="DashboardMainWrapper">
        <SideNav />
        <div className="DashboardWrapper">
          <div className="brandLogo">
            <img alt="" src={brandLogo} />
          </div>
          <div className="dashboardHeader">
            <h4>Reconciliation</h4>
            <div className="RightBlock"></div>
          </div>

          {!downloadButton ? (
            <Upload
              setReconcileData={(e) => setReconcileData(e)}
              handleHistoryId={(e) => setHistoryId(e)}
              handleDownload={(e) => handleDownload(e)}
            />
          ) : (
            <div>
              <CardContent sx={{ pt: 0 }} className="reconciliation-block">
                <Box sx={{ m: -1.5 }}>
                  <div className="downloadBlock">
                    {" "}
                    <LoadingButton
                      size="small"
                      color="primary"
                      onClick={(e) => onDownload(e)}
                      loading={loading}
                      loadingPosition="start"
                      startIcon={<SaveAltIcon />}
                      variant="contained"
                    >
                      <span>Download</span>
                    </LoadingButton>
                  </div>
                  <br />
                  <br />
                  <div className="centerBtn">
                    <Button
                      className="Darkbtn"
                      variant="contained"
                      onClick={() => setDownloadButton(!downloadButton)}
                    >
                      <UploadFileIcon />
                      Reset
                    </Button>
                  </div>
                </Box>
              </CardContent>
            </div>
          )}
          <SuccessModal toggle={downloadButton}/>
        </div>
      </section>
    </>
  );
};

export default Reconciliation;
