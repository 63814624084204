import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Unstable_Grid2";

import FormControl from "@mui/material/FormControl";
import {
  FormHelperText,
  FormGroup,
  FormLabel,
  InputLabel,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";

import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";

import { EventEmitter } from "../../components/Common/EventEmitter";

/////////////////ACTIONS///////////////////
import { editFormulaData } from "../../actions/formula";
import { size, values } from "lodash";
///////////////////
import { MentionsInput, Mention } from "react-mentions";

/////////
const Item = styled("div")(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  // textAlign: "center",
  // color: theme.palette.text.secondary,
}));

////////////////////
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const OperationTypes = [">", "=", "<", ">=", "<="];

const Schema = Yup.object().shape({
  title: Yup.string().required("Required"),
  isCondition: Yup.boolean(),
  calculate: Yup.string(),
  totalFormula: Yup.number().when("isCondition", {
    is: true,
    then: () =>
      Yup.number()
        .required("Required")
        .positive("must be greater than 0")
        .integer("Number of formulas must be an integer"),
  }),
  formulas: Yup.array().of(
    Yup.object().shape({
      operator: Yup.string().required("Required"),
      conditionValue: Yup.number()
        .required("Required")
        .min(1, "must by greater then 0"),
      formula: Yup.string().required("Required"),
    })
  ),
});

export default function EditFormula(props) {
  //   console.log(props.formulaData);
  const dispatch = useDispatch();
  const { entities, formulaData } = props;

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isCondition, setConditions] = React.useState(formulaData.isCondition);
  const [value, setValue] = React.useState(formulaData.formula);
  const [conditionalValue, setConditionalValue] = React.useState("");
  const [suggestions, setSuggestions] = React.useState();
  const [splits, setSplits] = React.useState([]);
  // const [selectedSplits, setSelectedSplits] = React.useState(formulaData.splits);
  const [numberOfFormulas, setNumberOfFormulas] = React.useState(0);
  const [selectedOption, setSelectedOption] = useState(
    formulaData.calculate ? formulaData.calculate : ""
  );
  // const [data, setData] = React.useState([]);
  // const isAllSelected = splits.length > 0 && selectedSplits.length === splits.length;
  let testData = [];
  // const [propertyType, setPropertyType] = React.useState("");

  const genrateFormula = () => ({
    operator: "",
    conditionValue: 0,
    formula: "",
  });

  const formik = useFormik({
    initialValues: {
      formulaId: formulaData._id,
      title: formulaData.title,
      // operationType: '',
      conditionValue: formulaData.conditionValue,
      isCondition: formulaData.isCondition,
      totalFormula: formulaData.formulas.length
        ? formulaData.formulas.length
        : "",
      formulas: formulaData.formulas,
      locations: formulaData.locations,
      calculate: selectedOption,
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      values.formula = value;
      values.calculate = selectedOption;
      values.isCondition = isCondition;
      if (isCondition) {
        // const conditions = {
        // 	// operator: values.operationType,
        // 	value: values.conditionValue,
        // };

        // values.conditions = conditions; //nozarorat
        values.isCondition = isCondition;
      }

      if (value === "" && !isCondition) {
        setErrorMessage("All fields are mandatory");
      } else if (value === "@" && !isCondition) {
        setErrorMessage("All fields are mandatory");
      } else if (values.totalFormula === "" && isCondition) {
        setErrorMessage("All fields are mandatory 2");
      } else if (errorMessage) {
      } else {
        setIsSubmitting(true);
        const req = editFormulaData(values);
        dispatch(req).then((d) => {
          EventEmitter.dispatch("message", "Success!");
          EventEmitter.dispatch("close");
        });
        setTimeout(() => {
          setIsSubmitting(false);
        }, 2000);
      }
    },
  });

  // console.log(formik.errors);

  // Function to handle value changes

  const handleChange = (e) => {
    const inputValue = e.target.value;
    errorMessage && setErrorMessage("");
    // isCondition ? setConditionalValue(inputValue) : setValue(inputValue);
    setValue(inputValue);
  };

  // const handleFormulaNumberChange = (e) => {
  // 	const inputValue = e.target.value;
  // 	console.log('inputValue', inputValue);

  // 	for (let i = 0; i < inputValue; i++) {
  // 		testData[i] = { operator: '', conditionValue: '', formula: '' };
  // 	}
  // 	console.log(testData);
  // 	setNumberOfFormulas(inputValue);
  // };

  const handleKeysRestriction = (e) => {
    const keyCode = e.keyCode || e.which;
    if (keyCode !== 64 && keyCode !== 35) {
      e.preventDefault();
    }
  };

  // const findSplit = (selected) => {
  // 	let splitArray = [];
  // 	const l = splits.filter((l) => selected.includes(l.id));
  // 	l.map((ls) => splitArray.push(ls.display));
  // 	return splitArray.join(', ');
  // };

  const handleValuesChange = (index, field, value) => {
    testData[index][field] = value;
  };

  // const handleChangeSplits = (event) => {
  // 	const {
  // 		target: { value },
  // 	} = event;
  // 	if (value[value.length - 1] === 'all') {
  // 		setSelectedSplits(selectedSplits.length === splits.length ? [] : splits.map((split) => split.id));
  // 	} else {
  // 		setSelectedSplits(
  // 			// On autofill we get a stringified value.
  // 			typeof value === 'string' ? value.split(',') : value
  // 		);
  // 	}
  // };

  useEffect(() => {
    const suggestion = [];
    const splitList = [];

    entities.map((n, i) => {
      if (n.documentType == "RevenueSplit") {
        splitList.push({ id: n._id, display: n.code });
      } else {
        suggestion.push({ id: n._id, display: n.code ? n.code : n.title });
      }
    });
    setSplits(splitList);
    setSuggestions(suggestion);
  }, []);
  return (
    <>
      <div className="create-entity-modal">
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {props.title}
        </Typography>

        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "100%" },
          }}
          onSubmit={formik.handleSubmit}
        >
          <TextField
            name="title"
            placeholder="Title"
            variant="outlined"
            type="text"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
          />
          {selectedOption ? (
            <FormControl component="fieldset">
              <FormLabel component="legend">
                Create formula to calculate
              </FormLabel>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedOption === "baserate"}
                      onChange={(e) => setSelectedOption(e.target.name)}
                      name="baserate"
                    />
                  }
                  label="Baserate"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedOption === "splits"}
                      onChange={(e) => setSelectedOption(e.target.name)}
                      name="splits"
                    />
                  }
                  label="Splits"
                />
              </FormGroup>
            </FormControl>
          ) : (
            ""
          )}
          <FormControlLabel
            control={
              <Checkbox
                value={formik.values.isCondition}
                defaultChecked={formik.values.isCondition}
                onChange={({ target }) => {
                  setConditions(target.checked);
                  formik.setFieldValue("isCondition", target.checked);
                  if (!target.checked) {
                    formik.setFieldValue("totalFormula", "");
                    formik.setFieldValue("formulas", []);
                  } else {
                    setValue("");
                  }
                }}
              />
            }
            label="Do you want to create conditional formula ?"
          />
          {isCondition && (
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Item>How many formulas you want to create</Item>
              </Grid>
              <Grid item xs={4}>
                <Item>
                  <TextField
                    name="totalFormula"
                    variant="outlined"
                    placeholder="Value"
                    type="number"
                    value={formik.values.totalFormula}
                    onChange={(e) => {
                      errorMessage && setErrorMessage("");
                      setNumberOfFormulas(isCondition ? e.target.value : 0);
                      return formik.handleChange(e);
                    }}
                    error={
                      formik.touched.totalFormula &&
                      Boolean(formik.errors.totalFormula)
                    }
                    helperText={
                      formik.touched.totalFormula && formik.errors.totalFormula
                    }
                  />
                </Item>
              </Grid>
            </Grid>
          )}

          {isCondition && (
            <FormulaComponent
              formik={formik}
              {...{
                handleValuesChange,
                conditionalValue,
                handleKeysRestriction,
                suggestions,
                setErrorMessage,
                numberOfFormulas,
                selectedOption,
                splits,
                formulaData,
              }}
            />
          )}

          <div>
            {/*
            isCondition && (
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Item>If Total Amount is </Item>
                </Grid>
                <Grid item xs={2}>
                  <Item>
                    <TextField
                      select
                      label="Select arithmetic operations"
                      variant="outlined"
                      name="operationType"
                      value={formik.values.operationType}
                      onChange={(e) => {
                        formik.handleChange(e);
                        // setPropertyType(e.target.value);
                      }}
                      error={
                        formik.touched.operationType &&
                        Boolean(formik.errors.operationType)
                      }
                      helperText={
                        formik.touched.operationType &&
                        formik.errors.operationType
                      }
                    >
                      {OperationTypes.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Item>
                  <Item>
                  
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <TextField
                      name="conditionValue"
                      variant="outlined"
                      placeholder="Value"
                      type="number"
                      value={formik.values.conditionValue}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.conditionValue &&
                        Boolean(formik.errors.conditionValue)
                      }
                      helperText={
                        formik.touched.conditionValue &&
                        formik.errors.conditionValue
                      }
                    />
                  </Item>
                </Grid>
                <Grid item xs={12}>
                  <Item>
                  <MentionsInput
                     value={conditionalValue}
                     onKeyPress={handleKeysRestriction}
                     onChange={handleChange}
                     style={{ height: "80px", marginBottom: 20 }}
                   >
                    <Mention
                      trigger="@"
                      data={suggestions}
                      style={{ backgroundColor: "#cee4e5" }}
                    />
                     <Mention
                       trigger="#"
                       data={[
                         { id: 1, display: "+" },
                         { id: 1, display: "-" },
                         { id: 1, display: "*" },
                         { id: 1, display: "/" },
                         { id: 1, display: "(" },
                         { id: 1, display: ")" },
                       ]}
                       style={{ backgroundColor: "green" }}
                     />
                 </MentionsInput>
                  </Item>
                </Grid>
              </Grid>
              
            )
            
            */}
          </div>

          {isCondition === false && (
            <MentionsInput
              value={value}
              onKeyPress={handleKeysRestriction}
              onChange={handleChange}
              style={{ height: "80px", marginBottom: 20 }}
            >
              <Mention
                trigger="@"
                data={
                  formulaData.calculate
                    ? selectedOption === "baserate"
                      ? suggestions
                      : splits
                    : suggestions
                }
                style={{ backgroundColor: "#cee4e5" }}
              />
              <Mention
                trigger="#"
                data={[
                  { id: 1, display: "+" },
                  { id: 2, display: "-" },
                  { id: 3, display: "*" },
                  { id: 4, display: "/" },
                  { id: 5, display: "(" },
                  { id: 6, display: ")" },
                ]}
                style={{ backgroundColor: "green" }}
              />
            </MentionsInput>
          )}

          {/*	{splits.length > 0 && (
						<FormControl fullWidth>
							<InputLabel id='demo-multiple-checkbox-label'>Splits:</InputLabel>
							<Select
								multiple
								label='Select Splits'
								variant='filled'
								name='splits'
								value={selectedSplits}
								onChange={handleChangeSplits}
								renderValue={(selected) => findSplit(selected)}
								MenuProps={MenuProps}
								input={<OutlinedInput label='Splits' />}>
								<MenuItem value='all'>
									<ListItemIcon>
										<Checkbox
											checked={isAllSelected}
											indeterminate={selectedSplits.length > 0 && selectedSplits.length < splits.length}
										/>
									</ListItemIcon>
									<ListItemText primary='Select All' />
								</MenuItem>
								{splits.map((option, key) => (
									<MenuItem key={key} value={option.id}>
										<Checkbox checked={selectedSplits.indexOf(option.id) > -1} />
										<ListItemText primary={option.display ? option.display : 'UNKNOWN'} />
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}  */}
          <Typography style={{ color: "red" }} variant="h6">
            {errorMessage}
          </Typography>

          <div className="text-center mt-2">
            <Button
              className="Bluebtn"
              variant="contained"
              fullWidth
              type="submit"
              disabled={isSubmitting}
            >
              Proceed
            </Button>
          </div>
        </Box>
      </div>
    </>
  );
}
// operator: '',
// 	conditionValue: 0,
// 	formula: '',

const FormulaComponent = ({
  formik,
  handleValuesChange,
  conditionalValue,
  handleKeysRestriction,
  suggestions,
  setErrorMessage,
  numberOfFormulas,
  selectedOption,
  splits,
  formulaData,
}) => {
  useEffect(() => {
    if (numberOfFormulas) {
      const genrateNewFormulas = Array.from({
        length: formik.values.totalFormula,
      }).map(() => ({
        operator: "",
        conditionValue: 0,
        formula: "",
      }));

      formik.setFieldValue("formulas", genrateNewFormulas);
    }
  }, [numberOfFormulas]);

  return (
    <div
      style={{
        overflowY: "scroll",
        overflowX: "hidden",
        maxHeight: "400px",
        marginBottom: "10px",
      }}
    >
      {formik.values.formulas.map((_, index) => {
        const value = formik.values.formulas[index];
        formik.values.formulas[index].formula.length === 1
          ? setErrorMessage("all fields menditory")
          : setErrorMessage("");
        const error = formik.errors?.formulas?.[index] || {};
        const touched = formik.touched?.formulas?.[index] || {};

        // console.log({ value, error, touched }, index, formik.touched, formik.errors);
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Item component="h2">{`Formula: ${index + 1}`}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item>If Total Amount is </Item>
            </Grid>
            <Grid item xs={2}>
              <Item>
                <TextField
                  select
                  label="Select arithmetic operations"
                  variant="outlined"
                  name={`formulas[${index}].operator`}
                  value={value.operator}
                  onChange={formik.handleChange}
                  error={touched.operator && Boolean(error.operator)}
                  helperText={touched?.operator && error?.operator}
                >
                  {OperationTypes.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Item>
              <Item></Item>
            </Grid>
            <Grid item xs={5}>
              <Item>
                <TextField
                  variant="outlined"
                  placeholder="Value"
                  type="number"
                  name={`formulas[${index}].conditionValue`}
                  value={value.conditionValue}
                  onChange={formik.handleChange}
                  error={
                    touched.conditionValue && Boolean(error.conditionValue)
                  }
                  helperText={touched?.conditionValue && error?.conditionValue}
                />
              </Item>
            </Grid>
            <Grid item xs={12}>
              <MentionsInput
                onKeyPress={handleKeysRestriction}
                style={{ height: "80px" }}
                name={`formulas[${index}].formula`}
                value={value.formula}
                onChange={({ target }) => {
                  formik.setFieldValue(
                    `formulas[${index}].formula`,
                    target.value
                  );
                }}
              >
                <Mention
                  trigger="@"
                  data={
                    formulaData.calculate
                      ? selectedOption === "baserate"
                        ? suggestions
                        : splits
                      : suggestions
                  }
                  style={{ backgroundColor: "#cee4e5" }}
                />
                <Mention
                  trigger="#"
                  data={[
                    { id: 1, display: "+" },
                    { id: 2, display: "-" },
                    { id: 3, display: "*" },
                    { id: 4, display: "/" },
                    { id: 5, display: "(" },
                    { id: 6, display: ")" },
                  ]}
                  style={{ backgroundColor: "green" }}
                />
              </MentionsInput>
              <FormHelperText
                style={{ marginBottom: 20 }}
                error={touched.formula && Boolean(error.formula)}
                helperText={touched?.formula && error?.formula}
              >
                {touched?.formula && error?.formula}
              </FormHelperText>
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
};
