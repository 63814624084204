import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { EventEmitter } from "../../components/Common/EventEmitter";
import { createLocation, clearErrors, clearLocations } from "../../actions/locations";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const Schema = Yup.object().shape({
  locationAddress: Yup.string()
    .min(2, "Too Short!")
    // .max(100, 'Too Long!')
    .required("Required"),
});

export default function Location() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const error = useSelector((state) => state.errors);
  const { locationRes } = useSelector((state) => state.location);
  useEffect(() => {
    if (error.message) {
      setErrorMessage(error.message);
      setIsSubmitting(false);
      dispatch(clearErrors());
    }
    if (locationRes && locationRes.success) {
      EventEmitter.dispatch("message", "Success!");
      EventEmitter.dispatch("close");
      dispatch(clearLocations());
    }
   
  }, [error, locationRes]);
  return (
    <>
      <div className="text-center add-user-modal ">
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className="mb-2"
        >
          Create Location
        </Typography>

        <Formik
          initialValues={{
            locationAddress: "",
          }}
          validationSchema={Schema}
          onSubmit={async (values) => {
            setIsSubmitting(true);
            dispatch(createLocation(values));
            // .then((d) => {
            //   EventEmitter.dispatch("message", "Success!");
            //   EventEmitter.dispatch("close");
            // });
            // setTimeout(() => {
            //   setIsSubmitting(false);
            // }, 2000);
          }}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form style={{ width: "500px" }}>
              <GooglePlacesAutocomplete
                selectProps={{
                  placeholder: "Address",
                  onChange: (s, action) =>
                    setFieldValue("locationAddress", s.label),
                }}
                apiKey={GOOGLE_MAPS_API_KEY}
              />
              {/* <Field type="text" placeholder="Address" name="locationAddress" className="create-location-feild"/> */}

              {touched.locationAddress && errors.locationAddress && (
                <div className="error">{errors.locationAddress}</div>
              )}
              <Typography style={{ color: "red" }} variant="h6">
                {errorMessage}
              </Typography>
              <Button
                type="submit"
                className="Bluebtn mt-3"
                disabled={isSubmitting}
              >
                Proceed
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
