import React from "react";
import FunctionsIcon from "@mui/icons-material/Functions";

import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PlaceIcon from "@mui/icons-material/Place";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import SummarizeIcon from "@mui/icons-material/Summarize";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CalculateIcon from "@mui/icons-material/Calculate";
import LogoutIcon from "@mui/icons-material/Logout";
import { get } from "lodash";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LogoutUser } from "../../actions/authActions";
import ArticleIcon from "@mui/icons-material/Article";
export const SideNav = (props) => {
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(LogoutUser());
  };
  const userData = useSelector((state) => state.auth.userData);
  const permissions = get(userData, "role.modules", []);
  const checkPermission = (moduleName) => {
    let obj = permissions.find((o) => o.name === moduleName);
    return get(obj, "permissions", { read: false });
  };

  const pathname = window.location.pathname;
  const options = [
    {
      title: "Reconciliation",
      icon: <FunctionsIcon className="icon" />,
      path: "reconciliation",
    },
    {
      title: "Reports",
      icon: <SummarizeIcon className="icon" />,
      path: "reports",
    },
    {
      title: "History",
      icon: <HistoryEduIcon className="icon" />,
      path: "history",
    },
    {
      title: "Locations",
      icon: <PlaceIcon className="icon" />,
      path: "locations",
    },
    {
      title: "Entities",
      icon: <CurrencyExchangeIcon className="icon" />,
      path: "entity",
    },

    {
      title: "Formula",
      icon: <CalculateIcon className="icon" />,
      path: "formula",
    },

    {
      title: "Users",
      icon: <PeopleAltIcon className="icon" />,
      path: "users",
    },
  ];

  return (
    <>
      <div className="sideBar">
        <ul>
          {get(userData, "role.title", "") === "superAdmin" ? (
            <li className={pathname === `/users` ? "active" : ""}>
              <Link to={"users"} className="tooltip">
                <PeopleAltIcon className="icon" />
                <span className="tooltiptext">Users</span>
              </Link>
            </li>
          ) : (
            options.map((o, index) => {
              const permission = checkPermission(o.title);
              if (
                permission.read ||
                (get(userData, "role.title", "") === "admin" &&
                  o.title === "Users")
              ) {
                return (
                  <li
                    key={index}
                    className={pathname === `/${o.path}` ? "active" : ""}
                  >
                    <Link to={o.path} className="tooltip">
                      {o.icon}
                      <span className="tooltiptext">{o.title}</span>
                    </Link>
                  </li>
                );
              }
            })
          )}
          {get(userData, "role.title", "") !== "superAdmin" && (
            <li className={pathname === `/formula` ? "active" : ""}>
              <Link to={"formula"} className="tooltip">
                <CalculateIcon className="icon" />
                <span className="tooltiptext">Formula</span>
              </Link>
            </li>
          )}
        </ul>

        <ul>
          <li className={pathname === `/profile` ? "active" : ""}>
            <Link to={"profile"} className="tooltip">
              <AccountCircleIcon className="icon" />
              <span className="tooltiptext">Profile</span>
            </Link>
          </li>
          {get(userData, "role.title", "") === "admin" && (
            <li className={pathname === `/settings` ? "active" : ""}>
              <Link to={"settings"} className="tooltip">
                <SettingsIcon className="icon" />
                <span className="tooltiptext">Settings</span>
              </Link>
            </li>
          )}
          {get(userData, "role.title", "") === "admin" && (
            <li className={pathname === `/api-docs` ? "active" : ""}>
              <Link to={"api-docs"} className="tooltip">
                <ArticleIcon className="icon" />
                <span className="tooltiptext">API-Docs</span>
              </Link>
            </li>
          )}

          <li>
            <a onClick={() => logout()} className="tooltip">
              <LogoutIcon className="icon" />
              <span className="tooltiptext">Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SideNav;
