import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Chip, TextField } from "@mui/material";
import { entriesIn, set } from "lodash";
import { FormHelperText } from "@mui/material";
import { calculateFormula } from "../../actions/formula";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const CalculationModal = (props) => {
  const { splits, filterFormula, conditional, selectedFormula } = props;
  const [splitList, setSplitList] = React.useState([]);
  const [formulaExpression, setFormulaExpression] = React.useState([]);
  const [totalValue, setTotalValue] = React.useState();
  const [calculatedAnswer, setCalculatedAnswer] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState("");
  // const [calculatedValues, setCalculatedValues] = React.useState({});
  const { entities } = useSelector((state) => state.entity);
  const ConditionValue =
    conditional !== "Default" ? makeCondition(conditional) : conditional;
  const dispatch = useDispatch();

  useEffect(() => {
    let Array = [];

    let formulaWithValues = filterFormula;
    // const l = entities.filter((l) => splits.includes(l._id));
    // l.map((ls) => Array.push(ls));

    filterFormula.forEach((item) => {
      const test = entities.filter((f) => {
        return f.code === item.code;
      });
      if (test.length > 0) {
        item["value"] = test[0].formula;
      }
    });
    setFormulaExpression(formulaWithValues);
    setSplitList(Array);
  }, [entities]);

  function makeCondition(str) {
    const val = str.split(" ");
    const condition = `if Total ${val[0]} ${val[1]}`;
    return condition;
  }

  const renderConditionChip = (condition) => {
    // const val=condition.split(' ')
    return (
      <Chip
        style={{ marginBottom: 5 }}
        label={`${ConditionValue}`}
        color="primary"
        variant="outlined"
      />
    );
  };

  const renderFormulaChip = () => {
    return filterFormula.map((item, index) => {
      return item.type == "operator" ? (
        <span style={{ marginBottom: 5 }} key={index}>
          {" "}
          {item.code}{" "}
        </span>
      ) : (
        <Chip
          style={{ marginBottom: 5 }}
          key={index}
          label={`${item.code}`}
          color="primary"
          variant="outlined"
        />
      );
    });
  };

  const renderFormulaValuesChip = () => {
    return formulaExpression.map((item, index) => {
      return item.type == "operator" ? (
        <span style={{ marginBottom: 5 }} key={index}>
          {" "}
          {item.code}{" "}
        </span>
      ) : (
        <Chip
          style={{ marginBottom: 5 }}
          key={index}
          label={`${item.value}`}
          color="primary"
          variant="outlined"
        />
      );
    });
  };

  const handleChange = (e) => {
    setErrorMessage("");
    setCalculatedAnswer("");
    setTotalValue(e.target.value);
  };

  const handleCalculation = async () => {
    if (!totalValue) return setErrorMessage(" Required");
    let test = true;

    if (conditional !== "Default") {
      const data = conditional.split(" ");
      test = eval(`${totalValue} ${data[0]} ${data[1]}`);
      !test && setErrorMessage(`total should be ${data[0]} ${data[1]}`);
    }
    if (test && errorMessage === "") {
      const obj = { formula: selectedFormula, amount: totalValue };
      const a = await dispatch(calculateFormula(obj));
      const l = a.data.baseRate ? a.data.baseRate : Object.values(a.data)[0];
      //     const formulaString= formulaExpression.map((item)=>{
      //     if (item.type === 'operator'){
      //      return  item.code
      //     } else {
      //       let returnValue;
      //       const containString=/[a-zA-Z]/.test(item.value)
      //       const changedValue= containString ? item.value.replace(/[(a-zA-Z)]+/,` ${totalValue}`):item.value
      //       const checkPercentage=item.value.indexOf('%')
      //       if (checkPercentage !== -1){
      //         const str = changedValue.slice().split(' ');
      //         returnValue = (str[0] /100) * str[2];

      //         }else returnValue = changedValue;
      //           return returnValue;
      //           // return item.value
      //     }
      //   }
      //  ).join('');

      //  const answer = eval(formulaString)
      setCalculatedAnswer(l);
    }
  };

  return (
    <div>
      <div style={{ display: "flex", gap: "25%" }}>
        <div>
          <span>Formula expression:</span>
          <div style={{ marginBottom: 10 }}>{renderFormulaChip()}</div>
        </div>
        <div>
          <span>Formula Condition:</span>
          <div>{renderConditionChip()}</div>
        </div>
      </div>

      <span>Formula with values:</span>
      <div style={{ marginBottom: 10 }}>{renderFormulaValuesChip()}</div>

      <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
        <div>
          <TextField
            name="total"
            placeholder="Total Value"
            variant="outlined"
            type="number"
            value={totalValue}
            onChange={handleChange}
            error={errorMessage}
            // helperText={errorMessage}
          />
          <FormHelperText style={{ width: "100%", color: "red" }}>
            {errorMessage}
          </FormHelperText>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <Button
            type="btn"
            className="Bluebtn"
            style={{ maxWidth: "100px" }}
            onClick={handleCalculation}
          >
            Go
          </Button>

          <TextField
            id="standard-basic"
            label="Answer"
            variant="standard"
            defaultValue={" "}
            value={calculatedAnswer}
            inputProps={{
              readOnly: true,
            }}
          />
        </div>
      </div>
      {/* // splitList.length > 0 ? */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>S.No.</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Condition</StyledTableCell>
              <StyledTableCell>Value</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formulaExpression.map((row, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell component="th" scope="row">
                  {i + 1}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {row.code}
                </StyledTableCell>
                {i === 0 ? (
                  <StyledTableCell component="th" scope="row">
                    {ConditionValue}
                  </StyledTableCell>
                ) : (
                  <StyledTableCell></StyledTableCell>
                )}
                <StyledTableCell component="th" scope="row">
                  {row.value}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>

    //   :
    //   <div>Loading....</div>
  );
};

export default CalculationModal;
