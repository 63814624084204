import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import Button from "@mui/material/Button";
import { deleteLocation, getAllLocations } from "../../actions/locations";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TablePagination from "@mui/material/TablePagination";
import DeleteModal from "./DeleteModal";
import Modal from "../../components/Modal";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const ListLocations = (props) => {
  const permission = props.permission;
  const [copied, setCopied] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [splitModal, setSplitModal] = React.useState(false);
  const [locationId, setLocationId] = React.useState("");
  const [allLocations, setAllLocations] = React.useState([]);
  const [tableSplits, setTableSplits] = React.useState([]);
  const [channels, setChannels] = React.useState([]);

  const { locations, loading } = useSelector((state) => state.location);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllLocations());
    setEditModal(false);
    setModal(false);
  }, [loading]);

  useEffect(() => {
    if (locations?.length > 0) {
      setAllLocations(locations);
    }
  }, [locations]);

  useEffect(() => {
    if (props.filterLocation) {
      const result = allLocations.filter((word) =>
        word.locationAddress
          .toLowerCase()
          .includes(props.filterLocation.toLowerCase())
      );
      setAllLocations(result);
    } else {
      setAllLocations(locations);
    }
  }, [props.filterLocation]);

  const deleteLocationById = (id) => {
    setLocationId(id);
    setModal(true);
    // dispatch(deleteLocation({ _id: id }));
  };
  const handleEdit = (id) => {
    setLocationId(id);
    setEditModal(true);
  };
  /////////////
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const splitsLength = (row) => {
    const splits = row.channel.map((el) => el.splits).flat();
    const uniq = [...new Set(splits)];

    return uniq.length;
  };

  /////////////
  return allLocations.length === 0 ? (
    <div className="noRecordMessage">No records found.</div>
  ) : (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>S.No.</StyledTableCell>
            <StyledTableCell>Address</StyledTableCell>
            <StyledTableCell>Lot Code</StyledTableCell>
            <StyledTableCell>Splits</StyledTableCell>
            <StyledTableCell align="right">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? allLocations.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : allLocations
          ).map((row, i) => (
            <StyledTableRow key={row._id}>
              <StyledTableCell component="th" scope="row">
                {i + 1}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {row.locationAddress}
              </StyledTableCell>
              {props.role === "superAdmin" && (
                <StyledTableCell>{row.user.fullName}</StyledTableCell>
              )}
              <StyledTableCell component="th" scope="row">
                <CopyToClipboard text={row.lotCode} onCopy={() => setCopied(i)}>
                  <span>
                    <ContentCopyIcon className="green-theme" />
                  </span>
                </CopyToClipboard>
                {i === copied ? (
                  <span style={{ color: "#00FF00" }}>Copied.</span>
                ) : (
                  ""
                )}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                <Button
                  onClick={() => {
                    setLocationId(row._id);
                    setTableSplits(row.splits);
                    setChannels(row.channel ? row.channel : []);
                    setSplitModal(true);
                  }}
                >
                  {row.channels?.length > 0
                    ? splitsLength(row)
                    : row.splits && row.splits.length}
                </Button>
              </StyledTableCell>
              <StyledTableCell align="right">
                {permission.edit && (
                  <Button onClick={() => handleEdit(row._id)}>
                    <EditIcon />
                  </Button>
                )}
                {permission.delete && (
                  <Button onClick={() => deleteLocationById(row._id)}>
                    <DeleteIcon color="error" />
                  </Button>
                )}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={allLocations.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {modal && (
        <DeleteModal
          openModal={modal}
          locationId={locationId}
          setModal={setModal}
        />
      )}
      {editModal && (
        <Modal
          name="edit-location"
          open={editModal}
          locationId={locationId}
          setClose={() => setEditModal(false)}
        />
      )}
      {splitModal && (
        <Modal
          name="split"
          open={splitModal}
          title={"Splits"}
          locationId={locationId}
          splits={tableSplits}
          channels={channels}
          setClose={() => setSplitModal(false)}
        />
      )}
    </TableContainer>
  );
};

export default ListLocations;
