import React from "react";
import { useDispatch } from "react-redux";

import brandLogo from "../../images/SettleIt.svg";
import "react-datepicker/dist/react-datepicker.css";

import Modal from "../../components/Modal";
import ConfirmDialog from "../../components/ConfirmDialog";
import { EventEmitter } from "../../components/Common/EventEmitter";

import SideNav from "../../components/NavBar";
import CreateReport from "./create";
import ListReports from "./list";
import { deleteReport } from "../../actions/report";

export const Report = () => {
  const dispatch = useDispatch();
  const [confirmDialogShow, setConfirmDialogShow] = React.useState(false);
  const [locationsModal, setLocationsModal] = React.useState(false);
  const [locations, setLocations] = React.useState([]);
  const [reportId, setReportId] = React.useState("");
  const [total, setTotal] = React.useState(0);

  const handleLocationModel = (locations) => {
    setLocationsModal(true);
    setLocations(locations);
  };
  const handleDelete = (id) => {
    setConfirmDialogShow(true);
    setReportId(id);
  };
  const handleReport = (report) => {
    setTotal(report.length);
  };
  const onDelete = () => {
    dispatch(deleteReport({ reportId })).then((d) => {
      EventEmitter.dispatch("message", "Success!");
      EventEmitter.dispatch("close");
      setConfirmDialogShow(false);
    });
  };
  return (
    <>
      <section className="DashboardMainWrapper">
        <SideNav />
        <div className="DashboardWrapper">
          <div className="brandLogo">
            <img src={brandLogo} />
          </div>
          <div className="dashboardHeader">
            <h4>
              Report<span>/ {total}</span>{" "}
            </h4>
            {/* <div className="RightBlock">
              <div className="searchBlock">
                <img src={searchIocn} />
                <input type="search" placeholder="Search..." />
              </div>
              <button type="btn" className="Bluebtn">
                Add User
              </button>
            </div> */}
          </div>
          <div className="row">
            <div className="col-lg-8">
              <div className="reportTable">
                <ListReports
                  // handleModel={handleModel}
                  handleLocationModel={handleLocationModel}
                  handleDelete={handleDelete}
                  handleReport={handleReport}
                  // searchData={searchData}
                />
               
              </div>
              {locationsModal && (
                <Modal
                  name="user-locations"
                  open={locationsModal}
                  // title={title}
                  locations={locations}
                  setClose={() => setLocationsModal(false)}
                />
              )}
              {confirmDialogShow && (
                <ConfirmDialog
                  open={confirmDialogShow}
                  // title={title}
                  locations={locations}
                  onDelete={onDelete}
                  setClose={() => setConfirmDialogShow(false)}
                />
              )}
            </div>
            <div className="col-lg-4">
              <h3>Schedule or Generate report</h3>
              <CreateReport />
              {/* <div className="reportBlock">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a className="nav-link active" data-bs-toggle="tab" href="#home">Weekly</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#menu1">Monthly</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#menu2">Yearly</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#menu2">Custom</a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane container active" id="home">
                  <div className="Scheduledate">
                    <div className="d-flex align-items-center">
                      <p>Schedule date</p>
                      <DatePicker />
                    </div>
                    <div className="datepickerBlock">
                      <img src={TimePickerIcon} />
                      <div className="d-flex align-items-center w-100">
                        <DatePicker />
                        <span>To</span>
                        <DatePicker />
                      </div>
                    </div>
                    <div className="datepickerBlock">
                      <img src={LocationIcon} />
                      <Select options={options} className="ReportLocation" />

                    </div>
                  </div>
                  <div className="footerBtn">
                    <a href="/" className="btn">Schedule</a>
                    <button className="Bluebtn">Generate now</button>
                  </div>
                </div>
                <div className="tab-pane container fade" id="menu1">
                  <div className="Scheduledate">
                    <div className="d-flex align-items-center">
                      <p>Schedule date</p>
                      <DatePicker />
                    </div>
                    <div className="datepickerBlock">
                      <img src={TimePickerIcon} />
                      <div className="d-flex align-items-center w-100">
                        <DatePicker />
                        <span>To</span>
                        <DatePicker />
                      </div>
                    </div>
                    <div className="datepickerBlock">
                      <img src={LocationIcon} />
                      <Select options={options} className="ReportLocation" />

                    </div>
                  </div>
                  <div className="footerBtn">
                    <a href="/" className="btn">Schedule</a>
                    <button className="Bluebtn">Generate now</button>
                  </div>
                </div>
                <div className="tab-pane container fade" id="menu2">
                  <div className="Scheduledate">
                    <div className="d-flex align-items-center">
                      <p>Schedule date</p>
                      <DatePicker />
                    </div>
                    <div className="datepickerBlock">
                      <img src={TimePickerIcon} />
                      <div className="d-flex align-items-center w-100">
                        <DatePicker />
                        <span>To</span>
                        <DatePicker />
                      </div>
                    </div>
                    <div className="datepickerBlock">
                      <img src={LocationIcon} />
                      <Select options={options} className="ReportLocation" />

                    </div>
                  </div>
                  <div className="footerBtn">
                    <a href="/" className="btn">Schedule</a>
                    <button className="Bluebtn">Generate now</button>
                  </div>
                </div>
                <div className="tab-pane container fade" id="menu2">
                  <div className="Scheduledate">
                    <div className="d-flex align-items-center">
                      <p>Schedule date</p>
                      <DatePicker />
                    </div>
                    <div className="datepickerBlock">
                      <img src={TimePickerIcon} />
                      <div className="d-flex align-items-center w-100">
                        <DatePicker />
                        <span>To</span>
                        <DatePicker />
                      </div>
                    </div>
                    <div className="datepickerBlock">
                      <img src={LocationIcon} />
                      <Select options={options} className="ReportLocation" />

                    </div>
                  </div>
                  <div className="footerBtn">
                    <a href="/" className="btn">Schedule</a>
                    <button className="Bluebtn">Generate now</button>
                  </div>
                </div>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Report;
