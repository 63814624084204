import {
    // GET_ERRORS,
    // CLEAR_ERRORS,
    ALL_ROLES_DATA,
    // GET_ROLES_FAIL,
    CREATE_ROLE,
    // CREATE_ROLE_FAIL,
    // EDIT_ROLE,
    // EDIT_ROLE_FAIL,
    DELETE_ROLE,
    // DELETE_ROLE_FAIL
  } from "../actions/types";
  
  const initialState = {
    roles: [],
    loading: false,
    message: ''
  };
  // reducer to handle auth Actions
  export default (state = initialState, action) => {
    switch (action.type) {
      case ALL_ROLES_DATA:
        return {
          ...state,
          loading: false,
          roles: action.payload.data
        };
      
      case DELETE_ROLE:
        return {
          ...state,
          loading: action.payload.data.success,
          message: action.payload.data.message,
        };
      
      case CREATE_ROLE:
        return {
          ...state,
          loading: action.payload.data.success,
          message: action.payload.data.message,
        };
      
      default:
        return state;
    }
  };
  