import {
  FILE_UPLOAD,
  RECONCILIATION,
} from "../actions/types";
const initialState = {
  loading: false,
  message: "",
  reconciledData: [],
};
// reducer to handle auth Actions
export default (state = initialState, action) => {
  switch (action.type) {
    case FILE_UPLOAD:
      return {
        ...state,
        loading: action.payload.data.success,
        message: action.payload.data.message,
        fileUploadRes: action.payload.data,
      };
    case RECONCILIATION:
      return {
        ...state,
        loading: action.payload.data.success,
        reconciledData: action.payload.data,
      };
    default:
      return state;
  }
};
