import {
  ENTITIES_DATA,
  ALL_ENTITYS_DATA,
  CREATE_ENTITY,
  DELETE_ENTITY,
  UPDATE_ENTITY,
} from "../actions/types";
const initialState = {
  entities: [],
  locationsEntities: [],
  loading: false,
  message: "",
};
// reducer to handle auth Actions
export default (state = initialState, action) => {
  switch (action.type) {
    case ALL_ENTITYS_DATA:
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };

    case DELETE_ENTITY:
      return {
        ...state,
        loading: action.payload.data.success,
        message: action.payload.data.message,
      };

    case CREATE_ENTITY:
      return {
        ...state,
        loading: action.payload.data.success,
        message: action.payload.data.message,
      };
    case UPDATE_ENTITY:
      return {
        ...state,
        loading: action.payload.data.success,
        message: action.payload.data.message,
      };
    case ENTITIES_DATA:
      return {
        ...state,
        locationsEntities: action.payload.data,
        loading: false,
      };

    default:
      return state;
  }
};
