import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import roleReducer from "./roleReducer";
import locationReducer from "./locationReducer";
import userReducer from "./userReducer";
import reportReducer from "./reportReducer";
import entityReducer from "./entityReducer";
import reconciliationReducer from "./reconciliationReducer";
import historyReducer from "./historyReducer";
import formula from "./Formula";
export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  form: formReducer,
  role: roleReducer,
  location: locationReducer,
  user: userReducer,
  report: reportReducer,
  entity: entityReducer,
  reconciliation: reconciliationReducer,
  history: historyReducer,
  formula: formula,
});
