import API from "../utils/API";
import {
  GET_ERRORS,
  CLEAR_ERRORS,
  CREATE_FROMULA,
  CREATE_FROMULA_FAIL,
  FORMULA_DATA,
  UPDATE_FROMULA,
  DELETE_FORMULA,
  DELETE_FORMULA_LOCATION,
  DELETE_FORMULA_FAIL,
  GET_FORMULA_FAIL,
  ASSIGN_FROMULA,
  DELETE_FORMULA_LOCATION_FAIL,
} from "./types";
import { getTokenInfo } from "./authActions";
// action to handle errors
export const getErrors = (errorData) => {
  return {
    type: GET_ERRORS,
    payload: errorData,
  };
};
// Action to clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export const createFormula = (data) => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    const response = await API.createFormula(data, headers);

    // // dispatch to reducer
    dispatch({
      type: CREATE_FROMULA,
      payload: response,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch delete entity failed
    dispatch({
      type: CREATE_FROMULA_FAIL,
    });
  }
};

export const editFormulaData = (data) => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to UPDATE FORMULA
    const response = await API.updateFormula(data, headers);

    // dispatch to reducer
    dispatch({
      type: UPDATE_FROMULA,
      payload: response,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch delete entity failed
    dispatch({
      type: CREATE_FROMULA_FAIL,
    });
  }
};

export const calculateFormula = (data) => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to delete formula
    const response = await API.calculateFormula(data, headers);

    return response.data;
    // dispatch to reducer
    //  dispatch({
    //   type: DELETE_FORMULA,
    //   payload: response,
    // });
  } catch (err) {
    // dispatch get error Action
    // dispatch(getErrors(err.response.data));
    // // dispatch formula fail
    // dispatch({
    //   type: DELETE_FORMULA_FAIL,
    // })
  }
};

export const assignLocations = (data) => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to delete entity
    const response = await API.assignLocations(data, headers);
    // dispatch to reducer
    dispatch({
      type: ASSIGN_FROMULA,
      payload: response,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch delete entity failed
    dispatch({
      type: CREATE_FROMULA_FAIL,
    });
  }
};

export const getAllFormula = () => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to fetch all entitys
    const response = await API.getFormula(headers);
    // dispatch to reducer
    dispatch({
      type: FORMULA_DATA,
      payload: response.data,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch fetch fail
    dispatch({
      type: GET_FORMULA_FAIL,
    });
  }
};

export const deleteFormula = (data) => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to delete formula
    const response = await API.deleteFormula(data, headers);

    // dispatch to reducer
    dispatch({
      type: DELETE_FORMULA,
      payload: response,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch formula fail
    dispatch({
      type: DELETE_FORMULA_FAIL,
    });
  }
};

export const deleteFormulaLocation = (data) => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to delete formula
    const response = await API.deleteFormulaLocation(data, headers);

    // dispatch to reducer
    dispatch({
      type: DELETE_FORMULA_LOCATION,
      payload: response,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch formula fail
    dispatch({
      type: DELETE_FORMULA_LOCATION_FAIL,
    });
  }
};
