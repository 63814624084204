import React from "react";
import { useSelector } from "react-redux";
import brandLogo from "../../images/SettleIt.svg"
import searchIocn from "../../images/serach-icon.svg"
import Button from '@mui/material/Button';
// import Modal from 'react-bootstrap/Modal';
import SideNav from "../../components/NavBar";
import Modal from "../../components/Modal";
import RoleList from "./list";

export const Roles = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const { roles } = useSelector((state) => state.role);
  return (
    <>
    <section className="DashboardMainWrapper">
        <SideNav />
        <div className="DashboardWrapper">
          <div className="brandLogo">
            <img alt="" src={brandLogo} />
          </div>
          <div className="dashboardHeader">
            <h4>Roles<span>/{roles.length}</span> </h4>
            <div className="RightBlock">
              <div className="searchBlock">
                <img alt=""  src={searchIocn} />
                <input type="search" placeholder="Search..." />
              </div>

              <Button type="btn" className="Bluebtn" onClick={() => setModalShow(true)} >Add New Role</Button>
              { modalShow && <Modal name="role" open={modalShow} setClose={() => setModalShow(false)}/>}
            </div>
          </div>
          <RoleList />
        </div>
        </section>
    </>
  )


};

export default Roles;
