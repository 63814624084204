import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// import Divider from '@mui/material/Divider';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { createRole } from '../../actions/role';
import { EventEmitter } from '../Common/EventEmitter';

const Schema = Yup.object().shape({
  title: Yup.string()
    .min(2, 'Too Short!')
    // .max(10, 'Too Long!')
    .required('Required'),
});

export default function Role() {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const modules = [
    {
      name: 'Reconciliation',
      permissions: {
        read: true,
        write: true,
        edit: false,
        delete: false,
      }
    },
    {
      name: 'Reports',
      permissions: {
        read: true,
        write: true,
        edit: false,
        delete: false,
      }
    },
    {
      name: 'History',
      permissions: {
        read: true,
        write: true,
        edit: false,
        delete: false,
      }
    },
    {
      name: 'Locations',
      permissions: {
        read: true,
        write: true,
        edit: false,
        delete: false,
      }
    },
    {
      name: 'Entities',
      permissions: {
        read: true,
        write: true,
        edit: false,
        delete: false,
      }
    }
  ]

  return (
    <>
      <div className='create-user-role'>
        <Typography id="modal-modal-title" variant="h6" component="h2" className='mb-3'>
          Create User Role
        </Typography>

        <Formik

          initialValues={{
            title: '',
            modules: modules,
          }}
          validationSchema={Schema}
          onSubmit={async (values) => {
            setIsSubmitting(true);
            dispatch(createRole(values)).then((d) => {
              EventEmitter.dispatch('message', 'Success!');
              EventEmitter.dispatch('close');
            });
            setTimeout(() => {
              setIsSubmitting(false);
            }, 2000);
          }}
        >
          {({ values, errors, touched }) => (
            <Form>
              <div className="d-flex align-items-center ">
                <h6>Title*</h6>
                <Field type="text" placeholder="Role title" name="title" className="create-location-feild" />
              </div>
              {touched.title && errors.title && <div className='error'>{errors.title}</div>}

              <h6 className='mt-4 mb-3'>Permissions</h6>
              <div className='Permissions-block' >
                {
                  values.modules.map((m, i) => (
                    <div className="d-flex align-items-center justify-content-between gap-5" key={i}>
                      <p>{m.name}</p>
                      <div className='permition-list'>
                        <div className="actionBtn read">
                          <label>
                            <Field type="checkbox" name={`modules[${i}].permissions.read`} checked={m.permissions.read} />
                            <span>View</span>
                          </label>
                        </div>
                        <div className="actionBtn write">
                          <label>
                            <Field type="checkbox" name={`modules[${i}].permissions.write`} checked={m.permissions.write} />
                            <span>Write</span>
                          </label>
                        </div>
                        <div className="actionBtn update">
                          <label>
                            <Field type="checkbox" name={`modules[${i}].permissions.edit`} checked={m.permissions.edit} />
                            <span>Edit</span>
                          </label>
                        </div>
                        <div className="actionBtn delete">
                          <label>
                            <Field type="checkbox" name={`modules[${i}].permissions.delete`} checked={m.permissions.delete} />
                            <span>Delete</span>
                          </label>
                        </div>
                      </div>

                    </div>
                  ))
                }


              </div>
              <div className='text-center mt-4'>
                <Button type="submit" className="Bluebtn" disabled={isSubmitting}>Submit</Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>



    </>
  );
}
