import {
  ALL_REPORT_DATA,
  CREATE_REPORT,
  DELETE_REPORT,
  GENERATE_NOW,
} from "../actions/types";
const initialState = {
  reports: [],
  loading: false,
  message: "",
  reportId: "",
};
// reducer to handle auth Actions
export default (state = initialState, action) => {
  switch (action.type) {
    case ALL_REPORT_DATA:
      return {
        ...state,
        loading: false,
        reports: action.payload.data,
      };

    case DELETE_REPORT:
      return {
        ...state,
        loading: action.payload.data.success,
        message: action.payload.data.message,
      };

    case CREATE_REPORT:
      return {
        ...state,
        loading: action.payload.data.success,
        message: action.payload.data.message,
        reportId: action.payload.data.reportId,
      };
    case GENERATE_NOW:
      return {
        ...state,
        loading: action.payload.data.success,
        reportNow: action.payload.data,
      };

    default:
      return state;
  }
};
