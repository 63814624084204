import API from "../utils/API";
import {
  GET_ERRORS,
  CLEAR_ERRORS,
  ENTITIES_DATA,
  ALL_ENTITYS_DATA,
  GET_ENTITYS_FAIL,
  CREATE_ENTITY,
  CREATE_ENTITY_FAIL,
  DELETE_ENTITY,
  DELETE_ENTITY_FAIL,
  UPDATE_ENTITY,
  UPDATE_ENTITY_FAIL
} from "./types";
import { getTokenInfo } from "./authActions";
// action to handle errors
export const getErrors = (errorData) => {
  return {
    type: GET_ERRORS,
    payload: errorData,
  };
};
// Action to clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export const createEntity = (data) => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to create entity
    const response = await API.createEntity(data, headers);
    // dispatch to reducer
    dispatch({
      type: CREATE_ENTITY,
      payload: response,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch create entity failed
    dispatch({
      type: CREATE_ENTITY_FAIL,
    });
  }
};

export const getAllEntities = () => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to fetch all entitys
    const response = await API.getEntitys(headers);
    // dispatch to reducer
    dispatch({
      type: ALL_ENTITYS_DATA,
      payload: response.data,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch fetch fail
    dispatch({
      type: GET_ENTITYS_FAIL,
    });
  }
};
export const getAllEntitiesLocationWise = () => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to fetch all entitys
    const response = await API.getEntitiesLocationWise(headers);
    // dispatch to reducer
    dispatch({
      type: ENTITIES_DATA,
      payload: response.data,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch fetch fail
    dispatch({
      type: GET_ENTITYS_FAIL,
    });
  }
};

export const deleteEntity = (data) => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to delete entity
    const response = await API.deleteEntity(data, headers);
    // dispatch to reducer
    dispatch({
      type: DELETE_ENTITY,
      payload: response,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch delete entity failed
    dispatch({
      type: DELETE_ENTITY_FAIL,
    });
  }
};

export const updateEntity = (data) => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to UPDATE entity
    const response = await API.updateEntity(data, headers);
    // dispatch to reducer
    dispatch({
      type: UPDATE_ENTITY,
      payload: response,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch update entity failed
    dispatch({
      type: UPDATE_ENTITY_FAIL,
    });
  }
};
