import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Role from "./role";
import User from "../../pages/Users/create";
import Location from "../../pages/Locations/create";
import UserLocations from "../../pages/Users/locationList";
import HistoryLocations from "../../pages/History/historyLocationList";
import CreateEntity from "../../pages/Entity/create";
import EditEntity from "../../pages/Entity/edit";
import ListEntity from "../../pages/Entity/list";
import CreateAdmin from "../../pages/Users/create-admin";
import CreateFormula from "../../pages/formula/createFormula";
import AssignLocations from '../../pages/formula/assignLocation';
import AssignSplits from "../../pages/Locations/assignsplits";
import { EventEmitter } from "../Common/EventEmitter";
import EditLocation from "../../pages/Locations/edit";
import { SplitModal } from "../../pages/formula/splitModal";
import CalculationModal from "../../pages/formula/calculationModal";
import EditFormula from '../../pages/formula/edit'
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 500,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal(props) {
  const [open, setOpen] = React.useState(props.open);
  const handleClose = () => {
    setOpen(false);
    props.setClose();
  };
  EventEmitter.subscribe("close", (event) => {
    handleClose(false);
  });

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="userModal"
      >
        <Box sx={style}>
          {props.name === "role" && <Role />}
          {props.name === "split" && <SplitModal {...props} />}
          {props.name === "calculation" && <CalculationModal {...props} />}
          {props.name === "user" && <User {...props} />}
          {props.name === "admin" && <CreateAdmin {...props} />}
          {props.name === "location" && <Location />}
          {props.name === "edit-location" && <EditLocation {...props}/>}
          {props.name === "user-locations" && <UserLocations {...props} />}
          {props.name === "history-locations" && <HistoryLocations {...props} />}
          {props.name === "entity" && <CreateEntity {...props} />}
          {props.name === "createFormula" && <CreateFormula {...props} />}
          {props.name === "edit-entity" && <EditEntity {...props} />}
          {props.name === "entity-list" && <ListEntity {...props} />}
          {props.name === "assign-locations" && <AssignLocations {...props} />}
          {props.name === "assign-splits" && <AssignSplits {...props} />}
          {props.name === "edit-formula" && <EditFormula {...props}/>}
        </Box>
      </Modal>
    </div>
  );
}
