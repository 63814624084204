import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import checkMark from "../images/checkmark.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function SuccessModal(props) {
  const [open, setOpen] = React.useState(props.toggle);
  const handleClose = () => {
    setOpen(false);
    props.toggle(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogActions>
          <div className="success-check-mark">
            <img alt="" src={checkMark} />
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
