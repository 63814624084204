import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import brandLogo from "../../images/SettleIt.svg";
import Andrew from "../../images/andrew.svg";
import SideNav from "../../components/NavBar";
import Modal from "../../components/Modal";
import searchIcon from "../../images/serach-icon.svg";
import { getHistory } from "../../actions/history";
import { useFormik } from "formik";
import moment from "moment";
import { Button } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
// import InfiniteScroll from 'react-infinite-scroller';
import InfiniteScroll from "react-infinite-scroller";

const initialValues = {
  startDate: "",
  endDate: "",
};

export const History = () => {
  const { historyData, loading } = useSelector((state) => state.history);
  const [historyModal, setHistoryModal] = useState(false);
  const [locations, setLocations] = useState([]);
  const [previousCount, setPreviousCount] = useState(0);
  const [count, setCount] = useState(0);
  const [filterHistory, setFilterHistory] = useState("");
  const [values, setValues] = useState(initialValues);

  const format = "YYYY-MM-DD";

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      startDate: values.startDate,
      endDate: values.endDate,
    },
  });

  useEffect(() => {
    if (filterHistory) {
      dispatch(getHistory(count, filterHistory, values));
    } else {
      const data = { count: 10, filterHistory: "", values: initialValues };
      dispatch(getHistory(data));
      setCount(10);
    }
  }, [filterHistory]);

  const handleHistoryModal = (locations) => {
    setLocations(locations);
    setHistoryModal(true);
  };

  const fetchMoreData = () => {
    if (!filterHistory && !(values.startDate && values.endDate)) {
      setTimeout(() => {
        if (count > 0) {
          setPreviousCount(historyData.length);
          if (historyData.length > 0) {
            dispatch(getHistory(count, filterHistory, values));
          }
          setCount(count + 10);
        }
      }, 500);
    }
  };

  const handleInputChange = (e, name) => {
    setValues({
      ...values,
      [name]: moment(e.$d).format(format),
    });
  };

  const handleDateFilter = () => {
    if (values.startDate && values.endDate) {
      dispatch(getHistory(count, filterHistory, values));
    } else {
      alert("please select both dates");
    }
  };

  const resetFilters = () => {
    setFilterHistory("");
    setValues(initialValues);
    dispatch(getHistory(10, "", initialValues));
  };

  return (
    <>
      <section className="DashboardMainWrapper">
        <SideNav />
        <div className="DashboardWrapper">
          <div className="brandLogo">
            <img alt="" src={brandLogo} />
          </div>
          <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
            Reconciliation History
          </h4>
          <div className="dashboardHeader">
            <h3>Date Filter:</h3>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker", "DateTimePicker"]}>
                <MobileDateTimePicker
                  className="time_picker"
                  name="startDate"
                  label="Start Date"
                  value={moment(formik.values.startDate).format(format)}
                  inputFormat="dd-MM-yyyy"
                  formatDate={(date) => moment(new Date()).format(format)}
                  onChange={(e) => handleInputChange(e, "startDate")}
                  error={
                    formik.touched.startDate && Boolean(formik.errors.startDate)
                  }
                  helperText={
                    formik.touched.startDate && formik.errors.startDate
                  }
                />
              </DemoContainer>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker", "DateTimePicker"]}>
                <MobileDateTimePicker
                  className="time_picker"
                  name="endDate"
                  label="End Date"
                  value={moment(formik.values.endDate).format(format)}
                  inputFormat="dd-MM-yyyy"
                  formatDate={(date) => moment(new Date()).format(format)}
                  onChange={(e) => handleInputChange(e, "endDate")}
                  error={
                    formik.touched.endDate && Boolean(formik.errors.endDate)
                  }
                  helperText={formik.touched.endDate && formik.errors.endDate}
                />
              </DemoContainer>
            </LocalizationProvider>
            <Button
              variant="outlined"
              color="success"
              onClick={handleDateFilter}
            >
              Filter
            </Button>
            <div className="RightBlockMinWidth">
              <div className="searchBlock">
                <img alt="searchBlock" src={searchIcon} />
                <input
                  type="search"
                  placeholder="Search..."
                  value={filterHistory}
                  onChange={(e) => {
                    setFilterHistory(e.target.value);
                  }}
                />
              </div>
              <Button variant="outlined" color="error" onClick={resetFilters}>
                reset all
              </Button>
            </div>
          </div>
          <InfiniteScroll
            pageStart={0}
            loadMore={""}
            hasMore={true || false}
            useWindow={previousCount == historyData.length ? true : false}
            loader={
              !loading || filterHistory ? null : (
                <div style={{ textAlign: "center" }} className="loader" key={0}>
                  Loading ...
                </div>
              )
            }
          >
            {historyData.map((n, i) => (
              <div className="historyTable" key={i}>
                <h4>{n.date}</h4>
                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      {n.data.map((item, index) => (
                        <tr className="reportRow" key={index}>
                          <td>{item.time}</td>
                          <td>
                            <p>
                              {" "}
                              {item.user.fullName}
                              <img alt="" src={Andrew} />
                              <strong>{item.paymentGatway}</strong>
                            </p>
                            <strong> Locations: </strong>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => handleHistoryModal(item.locations)}
                            >
                              {item.locations.length}
                            </Button>
                            <br />
                          </td>
                          <td>
                            <a
                              href={item.rowUrl}
                              target="_blank"
                              // rel="noreferrer"
                              rel="noopener noreferrer"
                            >
                              <button>Download Raw</button>
                            </a>{" "}
                            <a
                              href={item.reconciledUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <button>Download Reconciled</button>
                            </a>{" "}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </InfiniteScroll>
          <div className="noRecordMessage">
            {historyData.length === 0 ? "There are no records available!" : ""}
          </div>
        </div>
      </section>
      {historyModal && locations.length && (
        <Modal
          name="history-locations"
          open={historyModal}
          // title={title}
          locations={locations}
          setClose={() => setHistoryModal(false)}
        />
      )}
    </>
  );
};

export default History;
