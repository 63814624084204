import React, { useEffect } from "react";
import { get } from "lodash";
import * as yup from "yup";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";

import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { LoadingButton } from "@mui/lab";
import { ListItemIcon, Chip } from "@mui/material";

/////////////////DATE PICKER///////////
// import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";

/////////////////
import { EventEmitter } from "../../components/Common/EventEmitter";

/////////////////ACTIONS///////////////////
import { getAllLocations } from "../../actions/locations";
import { createReport, GenerateNow } from "../../actions/report";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const format = "YYYY-MM-DD HH:mm:ss";
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Schema = Yup.object().shape({
  scheduleDate: Yup.string()
    .min(2, "Too Short!")
    .max(10, "Too Long!")
    .required("Required"),
  startDate: yup
    .string("Enter your startDate")
    .required("startDate is required"),
  endDate: yup.string("Enter your endDate").optional("endDate is required"),
  locations: yup.array("Select locations").optional("Locations is required"),
  channels: yup.array("Select channels").optional("channels are required"),
});

const initialValues = {
  startDate: "",
  scheduleDate: "",
  endDate: "",
};
export default function CreateReport(props) {
  const dispatch = useDispatch();
  const [locationsData, setLocationsData] = React.useState([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [values, setValues] = React.useState(initialValues);
  const [loading, setLoading] = React.useState(false);
  // const [download, setDownload] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [fileUrl, setFileUrl] = React.useState("");
  const [channelsData, setChannelsData] = React.useState([]);
  const [selectedChannels, setSelectedChannels] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      startDate: values.startDate,
      scheduleDate: values.scheduleDate,
      endDate: values.endDate,
    },
    // validationSchema: Schema,

    onSubmit: (value) => {
      setIsSubmitting(true);
      value.locations = locationsData;
      value.startDate = values.startDate;
      value.scheduleDate = values.scheduleDate;
      value.endDate = values.endDate;
      // const afterDate = moment(values.startDate).add(1, "month").format(format);
      // const isTrue = moment(values.endDate).isAfter(afterDate, "month");
      const isTrue = false;
      if (
        locationsData.length < 1 ||
        values.startDate === "" ||
        values.endDate === ""
      ) {
        setErrorMessage("Start Date, End Date, and Locations fields mandatory");
      } else if (isTrue) {
        setErrorMessage("Duration can't be exceed!");
      } else {
        setIsSubmitting(true);
        const obj = {
          locations: locationsData,
          startDate: moment(values.startDate).utcOffset(0).format(format),
          endDate: moment(values.endDate).utcOffset(0).format(format),
          scheduleDate: moment(values.scheduleDate).utcOffset(0).format(format),
        };

        setErrorMessage("");
        // setLoading(true);
        dispatch(createReport(obj)).then((d) => {
          EventEmitter.dispatch("message", "Success!");
          EventEmitter.dispatch("close");
        });
        setTimeout(() => {
          setIsSubmitting(false);
        }, 2000);
      }
    },
  });
  //////////GET ROLES AND LOCATIONS///////////

  const { locations } = useSelector((state) => state.location);
  const isAllSelected =
    locations.length > 0 && locationsData.length === locations.length;
  const allChannelsSelected =
    channelsData.length > 0 && selectedChannels.length === channelsData.length;

  useEffect(() => {
    dispatch(getAllLocations());
  }, []);
  ///////////////////////////////
  const filterChannels = (data, all) => {
    let channel;
    if (!all) {
      channel = data
        ?.map((el) => {
          const location = locations.filter((e) => e._id === el);

          if (location[0].channels) return location[0].channels;
          else return [];
        })
        .flat();
    } else {
      const selectedAll = data
        .map((el) => {
          if (el.channels) {
            return el.channels;
          } else return [];
        })
        .flat();
      channel = selectedAll;
    }

    const uniq = channel.reduce((acc, currentValue) => {
      if (!acc.includes(currentValue)) {
        acc.push(currentValue);
      }
      return acc;
    }, []);
    return uniq;
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === "all") {
      if (locationsData.length === locations.length) setChannelsData([]);
      setLocationsData(
        locationsData.length === locations.length
          ? []
          : locations.map((location) => {
              const channels = filterChannels(locations, 1);
              setChannelsData(channels);
              return location._id;
            })
      );
    } else {
      const channel = filterChannels(value);
      setChannelsData(channel);
      setLocationsData(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    }
    setOpen(false);
  };

  const handleChangeChannels = (event) => {
    const {
      target: { value },
    } = event;
    if (value[value.length - 1] === "all") {
      if (selectedChannels.length === channelsData.length)
        setSelectedChannels([]);
      else {
        setSelectedChannels(channelsData);
      }
    } else {
      setSelectedChannels(value);
    }
  };

  const handleInputChange = (e, name) => {
    // const { name, value } = e.target;

    setValues({
      ...values,
      [name]: moment(e.$d).format(format),
    });
  };
  const OnGenerateNow = () => {
    const afterDate = moment(values.startDate).add(1, "month").format(format);
    // const isTrue = moment(values.endDate).isAfter(afterDate, "month");
    const isTrue = false;
    if (
      locationsData.length < 1 ||
      values.startDate === "" ||
      values.endDate === ""
    ) {
      setErrorMessage("Start Date, End Date, and Locations fields mandatory");
    } else if (isTrue) {
      setErrorMessage("Duration can't be exceed!");
    } else {
      const obj = {
        locations: locationsData,
        // startDate: moment(values.startDate).utcOffset(0).format(format),
        // endDate: moment(values.endDate).utcOffset(0).format(format),
        startDate: values.startDate,
        endDate: values.endDate,
        channels: selectedChannels,
        scheduleDate: moment().utcOffset(0).format(format),
      };
      setErrorMessage("");
      setLoading(true);
      // dispatch(createReport(obj)).then((d) => {});
      dispatch(GenerateNow(obj)).then((d) => {
        setLoading(false);
        setTimeout(() => {
          setFileUrl("");
        }, 2000);
      });
    }
  };
  const { reportNow } = useSelector((state) => state.report);
  // const { reportId } = useSelector((state) => state.report);
  const error = useSelector((state) => state.errors);
  useEffect(() => {
    if (reportNow && reportNow.success) {
      setFileUrl(reportNow.fileUrl);
      // window.open(reportNow.fileUrl, "_blank", "noreferrer");
    }
    if (reportNow && !reportNow.success) {
      const message = get(reportNow, "message", "");
      setErrorMessage(message);
    }
    // if (reportId !== "") {
    //   const obj = {
    //     locations: locationsData,
    //     startDate: values.startDate,
    //     endDate: values.endDate,
    //     scheduleDate: moment().format(format),
    //   };
    //   setErrorMessage("");
    //   obj.reportId = reportId;
    // }
  }, [reportNow]);
  const findAddress = (selected) => {
    let locationAddressArray = [];
    const l = locations.filter((l) => selected.includes(l._id));
    l.map((ls) => locationAddressArray.push(ls.locationAddress));
    return l;
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleDelete = (name) =>
    setLocationsData((prev) => prev.filter((loc) => loc !== name._id));

  const sliceString = (str) =>
    str.length > 16 ? `${str.slice(0, 15)}... ` : str;

  console.log(
    "test  time",
    moment("2023-06-06 17:35:00").utcOffset(0).format(format)
  );

  return (
    <>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        {props.title}
      </Typography>

      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        onSubmit={formik.handleSubmit}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DateTimePicker", "DateTimePicker"]}>
            <MobileDateTimePicker
              disablePast
              disableIgnoringDatePartForTimeValidation={true}
              className="time_picker"
              name="scheduleDate"
              label="Schedule Date"
              value={moment(formik.values.scheduleDate).format(format)}
              inputFormat="dd-MM-yyyy"
              formatDate={(date) => moment(new Date()).format(format)}
              onChange={(e) => handleInputChange(e, "scheduleDate")}
              error={
                formik.touched.scheduleDate &&
                Boolean(formik.errors.scheduleDate)
              }
              helperText={
                formik.touched.scheduleDate && formik.errors.scheduleDate
              }
            />
          </DemoContainer>
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DateTimePicker", "DateTimePicker"]}>
            <MobileDateTimePicker
              className="time_picker"
              name="startDate"
              label="Start Date"
              value={moment(formik.values.startDate).format(format)}
              inputFormat="dd-MM-yyyy"
              formatDate={(date) => moment(new Date()).format(format)}
              onChange={(e) => handleInputChange(e, "startDate")}
              error={
                formik.touched.startDate && Boolean(formik.errors.startDate)
              }
              helperText={formik.touched.startDate && formik.errors.startDate}
            />
          </DemoContainer>
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DateTimePicker", "DateTimePicker"]}>
            <MobileDateTimePicker
              className="time_picker"
              name="endDate"
              label="End Date"
              value={moment(formik.values.endDate).format(format)}
              inputFormat="dd-MM-yyyy"
              formatDate={(date) => moment(new Date()).format(format)}
              onChange={(e) => handleInputChange(e, "endDate")}
              error={formik.touched.endDate && Boolean(formik.errors.endDate)}
              helperText={formik.touched.endDate && formik.errors.endDate}
            />
          </DemoContainer>
        </LocalizationProvider>

        <FormControl fullWidth>
          <InputLabel id="demo-multiple-checkbox-label">Locations</InputLabel>
          <Select
            // id="filled-select-currency"
            multiple
            label="Select Locations"
            variant="filled"
            name="locations"
            value={locationsData}
            onChange={handleChange}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                  maxHeight: 150,
                  overflow: "auto",
                  scrollbarWidth: "none",
                }}
              >
                {findAddress(selected).map((value) => (
                  <Chip
                    key={value._id}
                    label={sliceString(value.locationAddress)}
                    onDelete={() => handleDelete(value)}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                  />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
            input={<OutlinedInput label="Locations" />}
            // error={formik.touched.locations && Boolean(formik.errors.locations)}
            // helperText={formik.touched.locations && formik.errors.locations}
          >
            <MenuItem value="all">
              <ListItemIcon>
                <Checkbox
                  checked={isAllSelected}
                  indeterminate={
                    locationsData.length > 0 &&
                    locationsData.length < locations.length
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Select All" />
            </MenuItem>
            {locations.map((option) => (
              <MenuItem key={option._id} value={option._id}>
                <Checkbox checked={locationsData.indexOf(option._id) > -1} />
                <ListItemText primary={option.locationAddress} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* channels */}
        {channelsData.length > 0 || selectedChannels.length > 0 ? (
          <Box mt={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-multiple-checkbox-label">
                Channels
              </InputLabel>
              <Select
                multiple
                label="Select Channels"
                variant="filled"
                name="channels"
                value={selectedChannels}
                onChange={handleChangeChannels}
                renderValue={(selected) => selectedChannels.join(", ")}
                MenuProps={MenuProps}
                input={<OutlinedInput label="Channels" />}
                // error={formik.touched.locations && Boolean(formik.errors.locations)}
                // helperText={formik.touched.locations && formik.errors.locations}
              >
                <MenuItem value="all">
                  <ListItemIcon>
                    <Checkbox
                      checked={allChannelsSelected}
                      indeterminate={
                        selectedChannels.length > 0 &&
                        selectedChannels.length < channelsData.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary="Select All" />
                </MenuItem>
                {channelsData.map((option, i) => (
                  <MenuItem key={i} value={option}>
                    <Checkbox checked={selectedChannels.includes(option)} />
                    <ListItemText primary={String(option)} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ) : (
          ""
        )}
        <Typography style={{ color: "red" }} variant="p">
          {errorMessage}
        </Typography>
        <div className="d-flex gap-2 mt-4">
          <Button
            className="Bluebtn"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isSubmitting}
          >
            Schedule
          </Button>
          {fileUrl === "" ? (
            <LoadingButton
              size="small"
              onClick={OnGenerateNow}
              loading={loading}
              className="Bluebtn"
              variant="contained"
              // disabled
            >
              <span> Generate Now</span>
            </LoadingButton>
          ) : (
            <a href={fileUrl} target="_blank" rel="noreferrer">
              <Button className="Bluebtn" variant="contained" fullWidth>
                Download
              </Button>
            </a>
          )}
        </div>
      </Box>
    </>
  );
}
