import React from 'react';
import brandLogo from "../../images/SettleIt.svg"
import ProfileIcon from "../../images/profile-icon.png"
import SideNav from '../../components/NavBar';
import { useDispatch ,useSelector } from 'react-redux';
import { EventEmitter } from '../../components/Common/EventEmitter';
import {loadUser } from '../../actions/authActions';
import { updateProfile } from '../../actions/userAction';
import { useState ,useEffect} from "react";
import { Formik, Field, Form } from 'formik';
import { get }  from 'lodash';
import * as Yup from 'yup';
// export const ProfilePage = () => {
  export default function ProfilePage() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const dispatch = useDispatch();

     useEffect(() => {
    dispatch(loadUser());
  }, []);
 let {userData}  = useSelector((state) => state.auth);
  const Schema = Yup.object().shape({
    password: Yup.string()
    .min(2, 'Too Short!')
    // .max(100, 'Too Long!')
    .required('Required'),
     reTypepassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .min(2, 'Too Short!')
    // .max(100, 'Too Long!')
    .required('Required')
});
  return (
    <>
      <section className="DashboardMainWrapper">
        <SideNav />
        <div className="DashboardWrapper">
            <Formik
        initialValues={{
          password: '',
          reTypepassword: ''
        }}
        validationSchema={Schema}
        onSubmit={async (values, { resetForm }) => {
           setIsSubmitting(true);
          dispatch(updateProfile(values)).then((d) => {
            EventEmitter.dispatch('message', 'Success!');
          });
           setTimeout(() => {
      setIsSubmitting(false);
    }, 2000);
    resetForm()
        }}
      >
        {({ values, errors, touched }) => (
          <Form>
  
          <div className="brandLogo">
            <img alt='' src={brandLogo} />
          </div>
          <div className="dashboardHeader">
            <h4>Profile</h4>
          </div>
          <div className='ProfileBg'>
            <span className='ProfileImgBox'>
              <div className='wrapperprofile'>
                <div className='box-wrapper'>
                  <div className='profileBlock'>
                    <div className='box'>
                      <img alt='' src={ProfileIcon} />
                      <div className='upload-options'>
                        <label>
                          <input type='file' className='image-upload' accept="image/*" />
                        </label>
                      </div>
                    </div>
                    <h4>{get(userData,'fullName','')}</h4>
                  </div>
                   <h6>{get(userData,'email','')}</h6>
                </div>
              </div>
            </span>
          </div>
          <div className='ProfileForm'>
            <div className='row'>
              {/* <div className='col-lg-6 col-md-12 col-sm-12'>
                <div className='form-input'>
                  <label for="text">First name</label>
                  <input type='text' placeholder='First name' />
                </div>
              </div>
              <div className='col-lg-6 col-md-12 col-sm-12'>
                <div className='form-input'>
                  <label for="text">Last name</label>
                  <input type='text' placeholder='Last name' />
                </div>
              </div> */}
              {/* <div className='col-lg-6 col-md-12 col-sm-12'>
                <div className='form-input'>
                  <label for="email">Email</label>
                  <input type='email' placeholder='Email id' />
                </div>
              </div> */}  
              <div className='col-lg-4 col-md-12 col-sm-12 mb-4'>
                <div className='form-input'>
                  <label for="password">Password</label>
                  {/* <input type='password' placeholder='Password' /> */}
                  <Field type="text" placeholder="Password" name="password"/>
                  </div>
               {touched.password && errors.password && <div className='error'>{errors.password}</div>}
          
              </div>
              <div className='col-lg-6 col-md-12 col-sm-12'>

              </div>
              <div className='col-lg-4 col-md-12 col-sm-12'>
                <div className='form-input'>
                  <label for="password">Re-type</label>
                  {/* <input type='password' placeholder='Re-type' /> */}
                    <Field type="text" placeholder="Re-type" name="reTypepassword"/>
                    </div>
                      {touched.reTypepassword && errors.reTypepassword && <div className='error'>{errors.reTypepassword}</div>}
              
              </div>
            </div>
          </div>
    <div className='text-center mt-5'>
    <button className='Bluebtn' disabled={isSubmitting}>Proceed</button>
    </div>
       
   </Form>
        )}
      </Formik>
      </div>

      </section>
    </>
  )

};

// export default ProfilePage;
