import React from "react";
// import reactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import App from "./components/App";
import { Provider } from "react-redux";
import { store } from "./store";
import "./style.scss";

/* reactDOM.render(
  // Wrap all APP with the react redux provider and pass the redux store to have access to global state
  <Provider store={store}>
    <App />
  </Provider>,
  document.querySelector("#root")
); */


const container = document.querySelector("#root")
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Provider store={store}>
  <App />
</Provider>);