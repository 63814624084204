import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import brandLogo from "../../images/SettleIt.svg";
import searchIocn from "../../images/serach-icon.svg";
import Modal from "../../components/Modal";
import SideNav from "../../components/NavBar";
import ListLocations from "./list";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { getAllEntities } from "../../actions/entity";

export const LocationPage = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const userData = useSelector((state) => state.auth.userData);
  const {locations} = useSelector((state)=> state.location)
  const {entities} = useSelector((state)=> state.entity)
  const [splitsModalShow, setSplitsModalShow] = React.useState(false);
  const [filterLocation, setFilterLocation] = React.useState("");
  const permissions = get(userData, "role.modules", []);
  const role = get(userData, "role.title", "");



  const checkPermission = (moduleName) => {
    let obj = permissions.find((o) => o.name === moduleName);
    return get(obj, "permissions", { read: false });
  };
  const permission = checkPermission("Locations");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllEntities());
  },[])

  return (
    <>
      <section className="DashboardMainWrapper">
        <SideNav />
        <div className="DashboardWrapper">
          <div className="brandLogo">
            <img alt="brandLogo" src={brandLogo} />
          </div>
          <div className="dashboardHeader">
            <h4>
              Locations<span></span>
            </h4>
            <div className="RightBlock">
              <div className="searchBlock">
                <img alt="searchBlock" src={searchIocn} />
                <input type="search" placeholder="Search..." 
                  onChange={(e) => setFilterLocation(e.target.value)} />
              </div>
              {permission.write && (
                <Button
                  type="btn"
                  className="Bluebtn"
                  onClick={() => setModalShow(true)}
                >
                  Add Location
                </Button>
              )}
               {permission.write && (
                <Button
                  type="btn"
                  className="Bluebtn"
                  onClick={() => setSplitsModalShow(true)}
                >
                  Assign Splits
                </Button>
              )}
              {modalShow && (
                <Modal
                  name="location"
                  open={modalShow}
                  setClose={() => setModalShow(false)}
                />
              )}
              {splitsModalShow && (
                <Modal
                  name="assign-splits"
                  open={splitsModalShow}
                  locations={locations}
                  entities={entities}
                  setClose={() => setSplitsModalShow(false)}
                />
              )}
            </div>
          </div>
          {permission.read && (
            <ListLocations permission={permission} role={role} filterLocation={filterLocation} />
          )}
        </div>
      </section>
    </>
  );
};

export default LocationPage;
