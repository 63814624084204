import API from "../utils/API";
import {
  GET_ERRORS,
  CLEAR_ERRORS,
  CREATE_LOCATION,
  CREATE_LOCATION_FAIL,
  ALL_LOCATIONS_DATA,
  GET_LOCATIONS_FAIL,
  DELETE_LOCATION,
  DELETE_LOCATION_FAIL,
  CLEAR_LOCATIONS,
  EDIT_LOCATION_FAIL,
  EDIT_LOCATION,
  ASSIGN_SPLITS,
  ASSIGN_SPLITS_FAIL,
  DELETE_LOCATION_SPLIT,
  DELETE_LOCATION_SPLIT_FAIL
} from "./types";
import { getTokenInfo } from "./authActions";
// action to handle errors
export const getErrors = (errorData) => {
  return {
    type: GET_ERRORS,
    payload: errorData,
  };
};
// Action to clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
export const clearLocations = () => {
  return {
    type: CLEAR_LOCATIONS,
  };
};
export const getAllLocations = () => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to fetch all roles
    const response = await API.getAllLocations(headers);
    // dispatch to reducer
    dispatch({
      type: ALL_LOCATIONS_DATA,
      payload: response.data,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch fetch fail
    dispatch({
      type: GET_LOCATIONS_FAIL,
    });
  }
};

export const createLocation = (data) => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to create role
    const response = await API.createLocation(data, headers);
    // dispatch to reducer
    dispatch({
      type: CREATE_LOCATION,
      payload: response,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch create role failed
    dispatch({
      type: CREATE_LOCATION_FAIL,
    });
  }
};

export const deleteLocation = (data) => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to delete role
    const response = await API.deleteLocation(data, headers);
    // dispatch to reducer
    dispatch({
      type: DELETE_LOCATION,
      payload: response,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch delete role failed
    dispatch({
      type: DELETE_LOCATION_FAIL,
    });
  }
};

export const editLocation = (data) => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to edit location
    const response = await API.editLocation(data, headers);
    // dispatch to reducer
    dispatch({
      type: EDIT_LOCATION,
      payload: response,
    });
  } catch (err) {
    dispatch(getErrors(err.response.data));
    dispatch({
      type: EDIT_LOCATION_FAIL,
    });
  }
};


export const assignSplits = (data) => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to delete entity
    const response = await API.assignSplits(data, headers);
    // dispatch to reducer
    dispatch({
      type: ASSIGN_SPLITS,
      payload: response,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch delete entity failed
    dispatch({
      type: ASSIGN_SPLITS_FAIL,
    });
  }
};

export const deleteLocationSplit= (data) => async (dispatch,getState)=>{
  try{
    const headers = getTokenInfo(getState);
    console.log(data);
    // send request to server side to delete formula
    const response = await API.removeSplit(data,headers);
    // console.log(response);
     // dispatch to reducer
     dispatch({
      type: DELETE_LOCATION_SPLIT,
      payload: response,
    });
  }catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch formula fail
    dispatch({
      type: DELETE_LOCATION_SPLIT_FAIL,
    })
  }
}