import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Chip from "@mui/material/Chip";
import { getAllRoles, deleteRole } from "../../actions/role";

export const RoleList = () => {
  const { roles, loading } = useSelector((state) => state.role);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllRoles());
  }, [loading])

  const deleteRoleById = (id) => {
    dispatch(deleteRole({ roleId: id }));
  }

  return (
     roles.length  === 0 ? (
        <div  className="noRecordMessage" >No records found.</div>
      ) : (
    <>
      <div className="RolesListBlock">
        <div className="row">
          {
            roles.map((role) => (
              <div key={role._id} className="col-lg-4 col-md-6">
                <div className="RoleList">
                  <div className="d-flex align-items-center justify-content-between mb-5">
                    <h4>{role.title}</h4>
                    <h6>{role.user} users</h6>
                    <Button onClick={() => deleteRoleById(role._id)}>
                      Delete
                    </Button>
                  </div>
                  <p>Permissions</p>
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="simple table">
                      <TableBody>
                        {role.modules.map((row) => (
                          <TableRow
                            key={row._id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="right">
                              <div className="permitionBlock">
                                <Chip label="V" color={row.permissions.read ? 'success': 'error'} variant="outlined" />
                                <Chip label="W" color={row.permissions.write ? 'success': 'error'} variant="outlined" />
                                <Chip label="E" color={row.permissions.edit ? 'success': 'error'} variant="outlined" />
                                <Chip label="D" color={row.permissions.delete ? 'success': 'error'} variant="outlined" />
                                {/* {row.permissions.read && <div className="permitions"><p>R</p></div>} */}
                                {/* {row.permissions.write && <div className="permitions"><p>W</p></div>} */}
                                {/* {row.permissions.edit && <div className="permitions"><p>E</p></div>} */}
                                {/* {row.permissions.delete && <div className="permitions"><p>D</p></div>} */}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </>
  )
  )
};

export default RoleList;
