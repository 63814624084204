import React, { useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import CalculateIcon from "@mui/icons-material/Calculate";
import EditIcon from "@mui/icons-material/Edit";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import Modal from "../../components/Modal";
import { Chip, Divider } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { string } from "joi";

const StyledTableCell = styled(TableCell)(({ theme }) => ({}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(1),
  },
}));

export const ListEntity = (props) => {
  const classes = useStyles();
  const { formulas } = props;
  const [splitModal, setSplitModal] = useState(false);
  const [formulaCondition, setFormulaCondition] = useState("Default");
  const [calculationModal, setCalculationModal] = useState(false);
  const [tableSplits, setTableSplits] = useState([]);
  const [filterFormula, setFilterFormula] = useState([]);
  const [selectedFormula, setSetlectedFormula] = useState([]);
  const permission = props.permission;
  const [condition, setConditionalValue] = React.useState(
    Array.from({ length: 20 }, () => "Default")
  );

  const deleteById = (id) => {
    props.handleDelete(id);
  };
  const extractFormulaString = (inputString) => {
    function convertStringToJSON(string) {
      const regex = /(@\[[^\]]+\]|\+|\(|\))/g;
      const jsonObject = [];
      const matches = string.matchAll(regex);
      for (const match of matches) {
        const entityName = match[1];
        jsonObject.push({
          code: entityName,
          type: "entity",
        });
      }
      return jsonObject;
    }

    var jsonObject = convertStringToJSON(inputString);

    jsonObject = jsonObject.filter((obj) => obj.code != "(");
    jsonObject = jsonObject.filter((obj) => obj.code != ")");
    jsonObject = jsonObject.map((obj) => {
      obj.code = obj.code.replace(/@\[(\S+)\]/, "$1");
      return obj;
    });
    return jsonObject;
  };

  const renderFormula = (formula) => {
    var extractedFormulaList = extractFormulaString(formula);
    var filteredData;

    filteredData = extractedFormulaList.filter(
      (obj) => obj.code !== "" || obj.type !== "operator"
    );
    for (let i = 0; i < filteredData.length; i++) {
      const currentObj = filteredData[i];
      const previousObj = filteredData[i - 1];
      const nextObj = filteredData[i + 1];

      if (currentObj.code === "" && nextObj && nextObj.code !== "") {
        currentObj.code = "(";
      }

      if (currentObj.code === "" && previousObj && previousObj.code !== "") {
        currentObj.code = ")";
      }

      if (["+", "-", "*", "/", "(", ")"].includes(currentObj.code)) {
        currentObj.type = "operator";
      }
    }

    return filteredData;
  };

  const renderFormulaChip = (formula) => {
    if (!formula) {
      return (
        <Chip
          style={{ marginBottom: 5 }}
          label={`Select a Condition`}
          color="warning"
          variant="outlined"
        />
      );
    }

    const filteredData = renderFormula(formula);

    return filteredData.map((item, index) => {
      return item.type == "operator" ? (
        <span style={{ marginBottom: 5 }} key={index}>
          {" "}
          {item.code}{" "}
        </span>
      ) : (
        <Chip
          key={index}
          style={{ marginBottom: 5 }}
          label={`${item.code}`}
          color="primary"
          variant="outlined"
        />
      );
    });
  };

  const handleChange = (e, index) => {
    const newConditions = [...condition];
    newConditions[index] = e.target.value;
    setConditionalValue(newConditions);
  };

  return formulas.length === 0 ? (
    <div className="noRecordMessage">No records found.</div>
  ) : (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>S.No.</StyledTableCell>
            <StyledTableCell>Title</StyledTableCell>
            <StyledTableCell>Condition</StyledTableCell>
            <StyledTableCell>Formula</StyledTableCell>
            <StyledTableCell>Locations</StyledTableCell>
            <StyledTableCell align="center">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formulas.map((row, i) => (
            <StyledTableRow key={row._id}>
              <StyledTableCell component="th" scope="row">
                {i + 1}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {row.title}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {row.isCondition === true ? (
                  <>
                    <div>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <InputLabel
                          id={`demo-simple-select-standard-label-${i}`}
                        >
                          Condition
                        </InputLabel>
                        <Select
                          labelId={`demo-simple-select-standard-label-${i}`}
                          id={`demo-simple-select-standard-${i}`}
                          value={condition[i]}
                          onChange={(e) => handleChange(e, i)}
                          label="Condition"
                        >
                          {row.formulas.map((f, index) => (
                            <MenuItem key={index} value={index}>
                              {`if Total ${f.operator} ${f.conditionValue}`}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </>
                ) : (
                  "Default"
                )}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {renderFormulaChip(
                      row.isCondition === true
                        ? typeof condition[i] !== "string"
                          ? row.formulas[condition[i]].formula
                          : ""
                        : row.formula
                    )}
                  </div>
                  <Button
                    onClick={() => {
                      setSetlectedFormula(row);
                      setTableSplits(row.splits);
                      setFilterFormula(
                        renderFormula(
                          typeof condition[i] !== "string"
                            ? row.formulas[condition[i]].formula
                            : row.formula
                        )
                      );
                      setFormulaCondition(
                        typeof condition[i] !== "string"
                          ? `${row.formulas[condition[i]].operator} ${
                              row.formulas[condition[i]].conditionValue
                            }`
                          : "Default"
                      );
                      setCalculationModal(true);
                    }}
                    startIcon={<CalculateIcon className="icon" />}
                  >
                    Calculate
                  </Button>
                </div>
              </StyledTableCell>

              <StyledTableCell component="th" scope="row">
                <Button
                  onClick={() => {
                    props.handleLocationModel(
                      row.locations,
                      row._id,
                      row.channels
                    );
                  }}
                >
                  {row.locations && row.locations.length}
                </Button>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" align="right">
                {permission.edit && (
                  <Button onClick={() => props.handleEdit(row, "a")}>
                    <EditIcon />
                  </Button>
                )}

                {permission.delete && (
                  <Button onClick={() => deleteById(row._id)}>
                    <DeleteIcon />
                  </Button>
                )}

                {permission.edit && (
                  <Button>
                    <AddLocationIcon
                      onClick={() =>
                        props.handleAssignFormula(
                          row.locations,
                          row._id,
                          row.channels ? row.channels : []
                        )
                      }
                    />
                  </Button>
                )}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      {splitModal && (
        <Modal
          open={splitModal}
          title={"Splits"}
          splits={tableSplits}
          setClose={() => setSplitModal(false)}
          name="split"
        />
      )}
      {calculationModal && filterFormula.length > 0 && (
        <Modal
          open={calculationModal}
          title="Calculations"
          splits={tableSplits}
          conditional={formulaCondition}
          filterFormula={filterFormula}
          selectedFormula={selectedFormula}
          setClose={() => setCalculationModal(false)}
          name="calculation"
        />
      )}
    </TableContainer>
  );
};

export default ListEntity;
