import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import { deleteLocationSplit } from "../../actions/locations";
import { EventEmitter } from "../../components/Common/EventEmitter";
import {
  Checkbox,
  FormControl,
  InputLabel,
  NativeSelect,
  OutlinedInput,
  Select,
} from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const SplitModal = (props) => {
  const { splits, locationId, channels } = props;
  const [allEntities, setAllEntities] = React.useState([]);
  const { entities } = useSelector((state) => state.entity);
  const dispatch = useDispatch();

  const deleteById = (splitId, locationId) => {
    dispatch(deleteLocationSplit({ splitId, locationId })).then((d) => {
      EventEmitter.dispatch("message", "Success!");
      EventEmitter.dispatch("close");
    });
  };
  useEffect(() => {
    let Array = [];
    if (channels.length) {
      const output = channels.reduce((acc, channel) => {
        channel.splits.forEach((split) => {
          const existingObject = acc.find((obj) => obj._id === split);
          if (existingObject) {
            existingObject.channels.push(channel.channel);
          } else {
            let l = entities.filter((l) => l._id === split);
            l[0].channels = [channel.channel];
            acc.push(l[0]);
          }
        });
        return acc;
      }, []);
      output.map((ls) => Array.push(ls));
      setAllEntities(Array);
    } else {
      const l = entities.filter((l) => splits.includes(l._id));
      l.map((ls) => Array.push(ls));
      setAllEntities(Array);
    }
  }, [entities]);

  return allEntities.length > 0 ? (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>S.No.</StyledTableCell>
            <StyledTableCell>Name</StyledTableCell>
            {channels.length > 0 ? (
              <StyledTableCell>Channels</StyledTableCell>
            ) : (
              ""
            )}
            <StyledTableCell>Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allEntities.map((row, i) => (
            <StyledTableRow key={row._id}>
              <StyledTableCell component="th" scope="row">
                {i + 1}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {row.title}
              </StyledTableCell>

              {row.channels ? (
                <FormControl fullWidth>
                  <InputLabel id="demo-multiple-checkbox-label"></InputLabel>
                  <NativeSelect
                    defaultValue={1}
                    disableUnderline
                    autoWidth
                    inputProps={{
                      name: "channels",
                      id: "uncontrolled-native",
                    }}
                  >
                    {row.channels.map((val, i) => (
                      <option key={i} value={val}>
                        <ListItemText primary={val} />
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              ) : (
                ""
              )}

              <StyledTableCell component="th" scope="row">
                <Button
                  color="error"
                  onClick={() => deleteById(row._id, locationId)}
                >
                  <DeleteIcon />
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <div>Loading....</div>
  );
};

export default SplitModal;
