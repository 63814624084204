import React, { useEffect } from "react";
import * as yup from "yup";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Unstable_Grid2";

import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import { EventEmitter } from "../../components/Common/EventEmitter";

/////////////////ACTIONS///////////////////
import { createEntity } from "../../actions/entity";
import { getAllLocations } from "../../actions/locations";
import { size } from "lodash";
///////////////////

const Item = styled("div")(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  // textAlign: "center",
  // color: theme.palette.text.secondary,
}));

////////////////////
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Types = ["Fixed", "Percentage", "Fixed & Percentage"];
const entityGroupBy = ["Service Fee", "Sales Tax", "Merchant Fee"];
const revenueGroupBy = [
  "Client Revenue",
  "Dock Revenue",
  "Sales Tax",
  "Commission",
];
const OperationTypes = ["+", "-"];
const Schema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Too Short!")
    // .max(10, "Too Long!")
    .required("Required"),
  type: yup.string("Select your typr").required("Type is required"),
  value: yup.number("Enter value").required("Value is required"),
  groupBy: yup.string("Enter groupBy").optional("optional"),
  // locations: yup.array("Select locations").optional("Locations is required"),
  // appliesOn: Yup.array()
  //   .required(" Applies is required")
  //   .min(1, "Please select at least one item"),
  propertyType: yup.string("Select your type").required("Type is required"),
});

export default function CreateEntity(props) {
  const dispatch = useDispatch();

  const { entities } = props;
  const [locationsData, setLocationsData] = React.useState([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [appliesOn, setAppliesOn] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [allApplies, setAllApplies] = React.useState();
  const [expression, setExpression] = React.useState();
  const [formula, setFormula] = React.useState("");
  const [formulaExpression, setFormulaExpression] = React.useState("");
  const [propertyType, setPropertyType] = React.useState("");
  const [entityType, setEntityType] = React.useState("");

  const formik = useFormik({
    initialValues: {
      title: "",
      type: "",
      groupBy: "",
      value: 0,
      operationType: "",
      operationValue: "",
      propertyType: props.title === "Create Entity" ? "addition" : "-",
      documentType: props.title === "Create Entity" ? "Entity" : "RevenueSplit",
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      values.locations = locationsData;
      values.appliesOn = appliesOn;
      values.formula = formulaExpression;
      values.value = formula;

      const isNamePresent =
        entities.filter((item) => item.title === values.title).length > 0;
      if (!isNamePresent) {
        if (
          appliesOn.length === 0 &&
          ((propertyType !== "Fixed" && props.title === "Create Entity") ||
            props.title !== "Create Entity")
        ) {
          setErrorMessage("All fields are mandatory");
        } else {
          setIsSubmitting(true);
          const req = createEntity(values);
          dispatch(req).then((d) => {
            EventEmitter.dispatch("message", "Success!");
            EventEmitter.dispatch("close");
          });
          setTimeout(() => {
            setIsSubmitting(false);
          }, 2000);
        }
      } else {
        alert("You cannot use same name!!");
      }
    },
  });

  const handleChangeApplyOn = (event) => {
    const {
      target: { value },
    } = event;
    setAppliesOn(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const findEntities = (selected) => {
    let Array = [];
    let expressionArray = [];
    const l = entities.filter((l) => selected.includes(l._id));
    l.map((ls) => {
      Array.push(ls.title);
      expressionArray.push(ls.value);
    });

    setAllApplies(Array.join("+"));
    setExpression(expressionArray.join("+"));

    return Array.join(", ");
  };

  useEffect(() => {
    onHandleFormula();
  });

  const onHandleFormula = () => {
    function solveEquation(expression) {
      try {
        return eval(expression);
      } catch (error) {
        return "Error: Invalid expression";
      }
    }

    let f = "";
    let formulaString = "";
    f = `${formik.values.value} `;
    formulaString = `${formik.values.value} `;

    if (appliesOn.length > 0) {
      if (
        formik.values.value !== 0 &&
        formik.values.type !== "" &&
        (formik.values.type !== "Fixed" ||
          formik.values.documentType == "RevenueSplit")
      ) {
        if (size(allApplies) > 0 && formik.values.type !== "Fixed") {
          formulaString = `${formulaString}%(${allApplies})`;
          f = `${f}/100*(${expression})`;
        }
        if (size(allApplies) > 0 && formik.values.type === "Fixed") {
          f = `(${expression})-${f}`;
          formulaString = `(${allApplies}) - ${formulaString}`;
        }
        if (formik.values.operationType !== "") {
          formulaString = `${formulaString}${formik.values.operationType}`;
          f = `${f}${formik.values.operationType}`;
        }
        if (formik.values.operationValue !== "") {
          f = `${f} ${formik.values.operationValue}`;
          formulaString = `${formulaString}${formik.values.operationValue}`;
        }
      }
    }
    setFormulaExpression(formulaString);
    setFormula(solveEquation(f));
  };

  return (
    <>
      <div className="create-entity-modal">
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {props.title}
        </Typography>

        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "100%" },
          }}
          onSubmit={formik.handleSubmit}
        >
          <TextField
            name="title"
            placeholder="Title"
            variant="outlined"
            type="text"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
          />

          <TextField
            select
            label={`Select ${
              props.title === "Create Entity" ? "Entity" : "Split"
            } Type`}
            variant="outlined"
            name="groupBy"
            value={formik.values.groupBy}
            onChange={(e) => {
              formik.handleChange(e);
              setEntityType(e.target.value);
            }}
            error={formik.touched.type && Boolean(formik.errors.type)}
            helperText={formik.touched.type && formik.errors.type}
          >
            {props.title === "Create Entity"
              ? entityGroupBy.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))
              : revenueGroupBy.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
          </TextField>

          <TextField
            select
            label="Select Value Type"
            variant="outlined"
            name="type"
            value={formik.values.type}
            onChange={(e) => {
              formik.handleChange(e);
              setPropertyType(e.target.value);
            }}
            error={formik.touched.type && Boolean(formik.errors.type)}
            helperText={formik.touched.type && formik.errors.type}
          >
            {Types.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>

          {propertyType !== "" && propertyType !== "Fixed" ? (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Item>
                  <TextField
                    name="value"
                    variant="outlined"
                    placeholder="Value"
                    type="number"
                    value={formik.values.value}
                    onChange={formik.handleChange}
                    error={formik.touched.value && Boolean(formik.errors.value)}
                    helperText={formik.touched.value && formik.errors.value}
                  />
                </Item>
              </Grid>
              <Grid item xs={2}>
                <Item>% </Item>
              </Grid>
              <Grid item xs={6}>
                <Item>
                  {" "}
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Of
                    </InputLabel>
                    <Select
                      // id="filled-select-currency"
                      multiple
                      label="Select Locations"
                      variant="filled"
                      name="appliesOn"
                      value={appliesOn}
                      onChange={handleChangeApplyOn}
                      // value={appliesOn}
                      // onChange={formik.handleChange}
                      renderValue={(selected) => findEntities(selected)}
                      MenuProps={MenuProps}
                      input={<OutlinedInput label="Apply On" />}
                      // error={
                      //   formik.touched.locations && Boolean(formik.errors.locations)
                      // }
                      // helperText={formik.touched.locations && formik.errors.locations}
                    >
                      {entities.map((option) => (
                        <MenuItem key={option._id} value={option._id}>
                          <Checkbox
                            checked={appliesOn.indexOf(option._id) > -1}
                          />
                          <ListItemText primary={option.title} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Item>
              </Grid>
            </Grid>
          ) : (
            <>
              <TextField
                name="value"
                variant="outlined"
                placeholder="Value"
                type="number"
                value={formik.values.value}
                onChange={formik.handleChange}
                error={formik.touched.value && Boolean(formik.errors.value)}
                helperText={formik.touched.value && formik.errors.value}
              />
              {props.title === "Revenue Split" && propertyType === "Fixed" && (
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <InputLabel id="demo-multiple-checkbox-label">Of</InputLabel>
                  <Select
                    // id="filled-select-currency"
                    multiple
                    label="Select Locations"
                    variant="filled"
                    name="appliesOn"
                    value={appliesOn}
                    onChange={handleChangeApplyOn}
                    // value={appliesOn}
                    // onChange={formik.handleChange}
                    renderValue={(selected) => findEntities(selected)}
                    MenuProps={MenuProps}
                    input={<OutlinedInput label="Apply On" />}
                    // error={
                    //   formik.touched.locations && Boolean(formik.errors.locations)
                    // }
                    // helperText={formik.touched.locations && formik.errors.locations}
                  >
                    {entities.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        <Checkbox
                          checked={appliesOn.indexOf(option._id) > -1}
                        />
                        <ListItemText primary={option.title} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </>
          )}
          {propertyType !== "" && propertyType === "Fixed & Percentage" && (
            <>
              <TextField
                select
                label="Select arithmetic operations"
                variant="outlined"
                name="operationType"
                value={formik.values.operationType}
                onChange={(e) => {
                  formik.handleChange(e);
                  // setPropertyType(e.target.value);
                }}
                error={
                  formik.touched.operationType &&
                  Boolean(formik.errors.operationType)
                }
                helperText={
                  formik.touched.operationType && formik.errors.operationType
                }
              >
                {OperationTypes.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                name="operationValue"
                variant="outlined"
                placeholder="Fixed Value"
                type="number"
                value={formik.values.operationValue}
                onChange={formik.handleChange}
                error={
                  formik.touched.operationValue &&
                  Boolean(formik.errors.operationValue)
                }
                helperText={
                  formik.touched.operationValue && formik.errors.operationValue
                }
              />
            </>
          )}
          {formula !== "" && (
            <Typography style={{ color: "green" }} variant="h6">
              Formula: {formula}
            </Typography>
          )}

          <Typography style={{ color: "red" }} variant="h6">
            {errorMessage}
          </Typography>
          <div className="text-center mt-2">
            <Button
              className="Bluebtn"
              variant="contained"
              fullWidth
              type="submit"
              disabled={isSubmitting}
            >
              Proceed
            </Button>
          </div>
        </Box>
      </div>
    </>
  );
}
