import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";

import { assign, get, find, chunk, size } from "lodash";

/////// MUI COMPONENT //////////
import { DropzoneArea } from "material-ui-dropzone";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Chip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

///////////////////////////
import { useSelector, useDispatch } from "react-redux";
import { getAllLocations } from "../../actions/locations";
import API from "../../utils/API";

import {
  fileUpload,
  Reconciliation,
  clearErrors,
} from "../../actions/reconciliation";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ListItemIcon } from "@mui/material";

const options = [
  { value: "stripe", label: "Stripe" },
  { value: "authorize.net", label: "Authorize.net" },
  { value: "paypal", label: "Paypal" },
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
/////////// SCHEMA/////////
const Schema = Yup.object().shape({
  paymentGatway: Yup.string().required("Required"),
  // file: Yup.object().required("Required"),
});

////////////////////////////
export const Upload = (props) => {
  const dispatch = useDispatch();

  const [file, setFile] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const [rowData, setRowData] = useState([]);
  const [paymentGatway, setPaymentGatway] = React.useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [locationsData, setLocationsData] = React.useState([]);
  const [transtionIds, setTranstionIds] = React.useState([]);
  const [totalProgressValue, setTotalProgressValue] = React.useState(0);
  const [progress, setProgress] = React.useState(0);
  const [fileUploadLoading, setFileUploadLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  /////////////////////

  const { locations } = useSelector((state) => state.location);
  const isAllSelected =
    locations.length > 0 && locationsData.length === locations.length;

  useEffect(() => {
    dispatch(getAllLocations());
  }, []);
  ///////////////////////////////
  const handleChangeLocations = (event) => {
    const {
      target: { value },
    } = event;
    if (value[value.length - 1] === "all") {
      setLocationsData(
        locationsData.length === locations.length
          ? []
          : locations.map((location) => location._id)
      );
    } else {
      setLocationsData(typeof value === "string" ? value.split(",") : value);
    }
    setOpen(false);
  };

  ///////////////////
  const formik = useFormik({
    initialValues: {
      paymentGatway: "",
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      values.locations = locationsData;
      values.file = file;
      if (locationsData.length <= 0 || transtionIds.length <= 0) {
        setErrorMessage("All fields are mandatory");
      } else {
        const trxId = transtionIds[0].transactionId;
        if (trxId === undefined) {
          setErrorMessage("This file not correct");
        } else {
          setLoading(true);
          // props.handleLoading(true);
          awsFileUpload(values);
          reconciliation(values);
        }
      }
    },
  });
  ///////////////// UPLOAD FILE ON AWS //////////
  const awsFileUpload = (values) => {
    let formData = new FormData(); //formdata object
    formData.append("paymentGatway", values.paymentGatway); // append the values with key, value pair
    formData.append("file", file);
    formData.append("locations", JSON.stringify(values.locations));
    dispatch(fileUpload(formData));
  };

  const reconciliation = async (values) => {
    const body = {
      paymentGatway: values.paymentGatway,
      locations: locationsData,
      transactionIds: transtionIds,
    };
    if (size(transtionIds) < 700 && size(transtionIds) > 0) {
      body.transactionIds = transtionIds;
      dispatch(Reconciliation(body));
    } else if (size(transtionIds) > 0) {
      multipleData(body);
    }
  };

  const multipleData = async (body) => {
    const newArray = chunk(transtionIds, 700);
    setTotalProgressValue(size(newArray));
    let next = -1;
    const reconciledData = [];
    let percentValue = 0;
    // eslint-disable-next-line complexity
    const nextFun = async () => {
      next++;
      if (next < newArray.length) {
        const items = get(newArray, `[${next}]`, {});
        body.transactionIds = items;
        const token = localStorage.getItem("token");
        const headers = {
          "Content-type": "application/json",
          token,
        };

        // send request to server side to register user
        const response = await API.reconciliation(body, { headers });
        const res = get(response, "data", {});
        if (res.success) {
          reconciledData.push(...res.reconciledData);
          percentValue = percentValue + 100 / newArray.length;
          setProgress(percentValue);
        }
        if (!res.success) {
          percentValue = percentValue + 100 / newArray.length;
          setProgress(percentValue);
        }
        await nextFun();
      } else {
        setLoading(false);
        if (size(reconciledData) === 0) {
          setErrorMessage("Transtions not found!");
        } else {
          setErrorMessage("");
          const finalData = setReconcileDatawithRowData(reconciledData);
          // setReconcileData(finalData);
          props.handleDownload(finalData);
        }
      }
    };
    await nextFun();
  };

  const setReconcileDatawithRowData = (data) => {
    const isData = [];
    if (paymentGatway === "stripe") {
      rowData.map((n) => {
        const items = find(data, (m) => {
          return m.id === n.id || m.id === n["PaymentIntent ID"];
        });
        if (items) {
          const newData = assign(n, items);
          isData.push(newData);
        }
      });
    } else {
      rowData.map((n) => {
        const items = find(data, (m) => {
          return (
            n["Invoice Number"] === m.id.slice(-n["Invoice Number"].length)
          );
        });
        if (items) {
          const newData = assign(n, items);
          isData.push(newData);
        }
      });
    }
    return isData;
  };
  const error = useSelector((state) => state.errors);
  // useEffect(() => {
  //   if (error.message) {
  //     setErrorMessage(error.message);
  //     dispatch(clearErrors());
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [error]);
  const { reconciledData } = useSelector((state) => state.reconciliation);

  useEffect(() => {
    if (error.message) {
      setErrorMessage(error.message);
      dispatch(clearErrors());
      setLoading(false);
    }
    if (reconciledData && reconciledData.success) {
      setLoading(false);
      const finalData = setReconcileDatawithRowData(
        reconciledData.reconciledData
      );
      props.handleDownload(finalData);
    }
    if (reconciledData && !reconciledData.success) {
      setLoading(false);
      setErrorMessage(reconciledData.message);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, reconciledData]);
  /////////// END UPLOAD FILE ON AWS /////////////
  const handleChange = (file) => {
    if (file.length > 0) {
      setFileUploadLoading(true);
      setFile(file[0]);
      fileReader(file[0]);
    }
  };
  const fileReader = (e) => {
    const f = e;
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws, {
        blankrows: false,
        rawNumbers: false,
      });
      const d = convertToJson(data);

      const headerType = checkPaymentGateway(paymentGatway);
      if (paymentGatway === "stripe") {
        const ids = d.map((item) => {
          return {
            transactionId: item[headerType.head],
            amount: item[headerType.amountKey],
            PaymentIntentId: item[headerType.PaymentIntentId],
          };
        });
        setTranstionIds(ids);
        setFileUploadLoading(false);
      } else {
        const ids = d.map((item) => {
          return {
            transactionId: item[headerType.head],
            amount: item[headerType.amountKey] || item[headerType.amountKey2],
          };
        });
        setTranstionIds(ids);
        setFileUploadLoading(false);
      }
    };

    reader.readAsBinaryString(e);
  };

  const convertToJson = (csv) => {
    const lines = csv.split("\n");
    const result = [];
    const headers = lines[0].split(",");

    for (let i = 1; i < lines.length; i++) {
      const obj = {};
      const currentline = lines[i].split(",");

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }
      result.push(obj);
    }
    setRowData(result);
    return result;
  };
  const checkPaymentGateway = (paymentGatway) => {
    const data = [
      {
        PaymentGateway: "stripe",
        head: "id",
        amountKey: "Amount",
        amountKey2: "Converted Amount",
        PaymentIntentId: "PaymentIntent ID",
      },
      {
        PaymentGateway: "authorize.net",
        head: "Invoice Number",
        amountKey: "Processed Transaction Amount",
        amountKey2: "Processed Sales Amount",
      },
    ];
    // let isData = {};
    const isData = data.filter((item) => {
      return paymentGatway === item.PaymentGateway;
    });
    return isData[0];
  };

  const findAddress = (selected) => {
    let locationAddressArray = [];
    const l = locations.filter((l) => selected.includes(l._id));
    l.map((ls) => locationAddressArray.push(ls.locationAddress));
    return l;
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleDelete = (name) =>
    setLocationsData((prev) => prev.filter((loc) => loc !== name._id));

  const sliceString = (str) =>
    str.length > 16 ? `${str.slice(0, 15)}... ` : str;

  return (
    <>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "15ch" },
        }}
        onSubmit={formik.handleSubmit}
      >
        <div>
          {loading === true ? (
            <div className="loadingBlock">
              <CircularProgressbar
                value={50}
                maxValue={totalProgressValue}
                text={`${progress.toFixed(0)}%`}
                styles={buildStyles({
                  textColor: "red",
                  pathColor: "turquoise",
                  trailColor: "gold",
                })}
              />
              ;
            </div>
          ) : (
            <>
              <div className="payment-gateway-wrapper">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select payment gateway
                  </InputLabel>
                  <Select
                    //   labelId="demo-simple-select-label"
                    //   id="demo-simple-select"
                    name="paymentGatway"
                    input={<OutlinedInput label="Select payment gateway" />}
                    // value={paymentGatway}
                    // onChange={(e) => setPaymentGatway(e)}
                    value={formik.values.paymentGatway}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setPaymentGatway(e.target.value);
                    }}
                    error={
                      formik.touched.paymentGatway &&
                      Boolean(formik.errors.paymentGatway)
                    }
                    helperText={
                      formik.touched.paymentGatway &&
                      formik.errors.paymentGatway
                    }
                  >
                    {options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br />
                <br />
                <FormControl fullWidth>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Locations
                  </InputLabel>
                  <Select
                    // id="filled-select-currency"
                    multiple
                    label="Select Locations"
                    variant="filled"
                    name="locations"
                    value={locationsData}
                    onChange={handleChangeLocations}
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    renderValue={(selected) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 0.5,
                          maxHeight: 150,
                          overflow: "auto",
                          scrollbarWidth: "none",
                        }}
                      >
                        {findAddress(selected).map((value) => (
                          <Chip
                            key={value._id}
                            label={sliceString(value.locationAddress)}
                            onDelete={() => handleDelete(value)}
                            onMouseDown={(event) => {
                              event.stopPropagation();
                            }}
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                    input={<OutlinedInput label="Locations" />}
                    // error={formik.touched.locations && Boolean(formik.errors.locations)}
                    // helperText={formik.touched.locations && formik.errors.locations}
                  >
                    <MenuItem value="all">
                      <ListItemIcon>
                        <Checkbox
                          checked={isAllSelected}
                          indeterminate={
                            locationsData.length > 0 &&
                            locationsData.length < locations.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText primary="Select All" />
                    </MenuItem>
                    {locations.map((option, key) => (
                      <MenuItem key={key} value={option._id}>
                        <Checkbox
                          checked={locationsData.indexOf(option._id) > -1}
                        />
                        <ListItemText primary={option.locationAddress} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <DropzoneArea
                  showFileNames
                  // acceptedFiles={["xlsx", "csv"]}
                  dropzoneText="Drop your file here (xlsx,csv)"
                  showAlerts={false}
                  // filesLimit={1}
                  name="file"
                  maxFileSize={10000000}
                  // showPreviews={true}
                  onChange={handleChange}
                  // value={formik.values.file}

                  // onChange={formik.setFieldValue("file", Array.from(set))}
                  // error={formik.touched.file && Boolean(formik.errors.file)}
                  // helperText={formik.touched.file && formik.errors.file}
                />
                <Typography style={{ color: "red" }} variant="h6">
                  {errorMessage}
                </Typography>
                {fileUploadLoading && (
                  <Typography style={{ color: "green" }} variant="h6">
                    File Reading, Please wait ....
                  </Typography>
                )}
                {!fileUploadLoading && (
                  <Button
                    className="Bluebtn"
                    variant="contained"
                    fullWidth
                    type="submit"
                  >
                    Proceed
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </Box>
    </>
  );
};

export default Upload;
