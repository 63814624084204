import API from "../utils/API";
import {
  GET_ERRORS,
  CLEAR_USER,
  CLEAR_ERRORS,
  ALL_USERS_DATA,
  GET_USERS_FAIL,
  DELETE_USERS,
  DELETE_USERS_FAIL,
  UPDATE_USERS,
  UPDATE_USERS_FAIL,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_FAIL,
} from "./types";
import { getTokenInfo } from "./authActions";
// action to handle errors
export const getErrors = (errorData) => {
  return {
    type: GET_ERRORS,
    payload: errorData,
  };
};
// Action to clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export const clearUser = () => {
  return {
    type: CLEAR_USER,
  };
};

export const getAllUsers = () => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to fetch all roles
    const response = await API.getAllUsers(headers);
    // dispatch to reducer
    dispatch({
      type: ALL_USERS_DATA,
      payload: response.data,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch fetch fail
    dispatch({
      type: GET_USERS_FAIL,
    });
  }
};

export const deleteUser = (data) => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to delete role
    const response = await API.deleteUser(data, headers);
    // dispatch to reducer
    dispatch({
      type: DELETE_USERS,
      payload: response,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch delete role failed
    dispatch({
      type: DELETE_USERS_FAIL,
    });
  }
};
export const updateUser = (data) => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to delete role
    const response = await API.updateUser(data, headers);
    // dispatch to reducer
    dispatch({
      type: UPDATE_USERS,
      payload: response.data,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch delete role failed
    dispatch({
      type: UPDATE_USERS_FAIL,
    });
  }
};

export const updateProfile = (data) => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to delete role
    const response = await API.updateProfile(data, headers);
    // dispatch to reducer
    dispatch({
      type: UPDATE_USER_PROFILE,
      payload: response,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch delete role failed
    dispatch({
      type: UPDATE_USER_PROFILE_FAIL,
    });
  }
};
