import React, { useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { getAllReport } from "../../actions/report";

// import { EventEmitter } from "../../components/Common/EventEmitter";
const format = "lll";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const ListReports = (props) => {
  const { loading, reports } = useSelector((state) => state.report);
  // let { users } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllReport());
  }, [loading]);

  props.handleReport(reports);

  const deleteById = (id) => {
    props.handleDelete(id);
    // dispatch(deleteUser({ _id: id })).then((d) => {
    //   EventEmitter.dispatch("message", "Success!");
    //   EventEmitter.dispatch("close");
    // });
  };

  return reports.length === 0 ? (
    <div className="noRecordMessage">No records found.</div>
  ) : (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>S.No.</StyledTableCell>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell>Report</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell>Locations</StyledTableCell>
            <StyledTableCell align="right">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.map((row, i) => (
            <StyledTableRow key={row._id}>
              <StyledTableCell component="th" scope="row">
                {i + 1}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {moment(row.scheduleDate).format(format)}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {moment(row.startDate).format(format)} to{" "}
                {moment(row.endDate).format(format)}
              </StyledTableCell>{" "}
              <StyledTableCell component="th" scope="row">
                {row.status === "Scheduled" ? (
                  <p className="bluestatus">{row.status}</p>
                ) : (
                  <p className="greenstatus">{row.status}</p>
                )}
              </StyledTableCell>{" "}
              <StyledTableCell align="right">
                <Button
                  onClick={() => props.handleLocationModel(row.locations)}
                >
                  {/* <RemoveRedEyeIcon /> */}
                  {row.locations && row.locations.length}
                </Button>
              </StyledTableCell>
              <StyledTableCell align="right">
                {row.status !== "Scheduled" && (
                  <a href={row.reconciledUrl} target="_blank" rel="noreferrer">
                    <Button>
                      <FileDownloadIcon />
                    </Button>
                  </a>
                )}
                <Button onClick={() => deleteById(row._id)}>
                  <DeleteIcon color="error" />
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListReports;
