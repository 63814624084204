import React from "react";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import { impersonateUser } from "../../actions/authActions";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const ListUsers = (props) => {
  const dispatch = useDispatch();

  const deleteUserById = (id) => {
    props.handleDelete(id);
  };

  const impersonateUserAction = (email) => {
    dispatch(impersonateUser({ email }));
  };
  return props.users.length === 0 ? (
    <div className="noRecordMessage">No records found.</div>
  ) : (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>S.No.</StyledTableCell>
            <StyledTableCell>Full Name</StyledTableCell>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell>Mobile</StyledTableCell>
            <StyledTableCell>Role</StyledTableCell>
            {props.role !== "superAdmin" && (
              <StyledTableCell>Locations</StyledTableCell>
            )}
            <StyledTableCell align="right">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.users.map((row, i) => (
            <StyledTableRow key={row._id}>
              <StyledTableCell component="th" scope="row">
                {i + 1}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {row.fullName}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {row.email}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {row.mobile}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {row.role.title}
              </StyledTableCell>
              {props.role !== "superAdmin" && (
                <StyledTableCell align="right">
                  <Button
                    onClick={() => props.handleLocationModel(row.locations)}
                  >
                    {row.locations && row.locations.length}
                  </Button>
                </StyledTableCell>
              )}
              <StyledTableCell align="right">
                <Button onClick={() => props.handleModel(row)}>
                  <EditIcon />
                </Button>
                <Button onClick={() => deleteUserById(row._id)}>
                  <DeleteIcon color="error" />
                </Button>
                <Button onClick={() => impersonateUserAction(row.email)}>
                  <SelfImprovementIcon className="green-theme"/>
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListUsers;
