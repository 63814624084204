import axios from "axios";
// import { getHistory } from "../actions/history";

// const API_PATH = "http://localhost:3000";
const API_PATH = "/api";
//as
export default {
  updateUserSelf(data, headers) {
    return axios.post(`${API_PATH}/auth/updateUser`, data, headers);
  },
  passowrdReset(data, headers) {
    return axios.post(`${API_PATH}/auth/password-reset`, data, headers);
  },
  login(data) {
    return axios.post(`${API_PATH}/auth/login`, data);
  },
  impersonateUser(data, headers) {
    return axios.post(`${API_PATH}/auth/impersonate`, data, headers);
  },
  loadUser(headers) {
    return axios.get(`${API_PATH}/auth/me`, headers);
  },

  getUserByEmail(data) {
    return axios.post(`${API_PATH}/auth/getUserByEmail`, data);
  },
  getAllVendors(headers) {
    return axios.get(`${API_PATH}/auth/getAllVendors`, headers);
  },

  userActiveAndDeactive(data, headers) {
    return axios.post(`${API_PATH}/auth/update-active-deactive`, data, headers);
  },
  /////////// START USERS ////////
  register(data, headers) {
    return axios.post(`${API_PATH}/auth/register`, data, headers);
  },
  registerAdmin(data, headers) {
    return axios.post(`${API_PATH}/auth/register-admin`, data, headers);
  },
  updateAdminDetails(data, headers) {
    return axios.put(`${API_PATH}/auth/update-admin`, data, headers);
  },
  getAllUsers(headers) {
    return axios.get(`${API_PATH}/user/getAllusers`, headers);
  },
  deleteUser(data, headers) {
    return axios.post(`${API_PATH}/user/delete`, data, headers);
  },
  updateUser(data, headers) {
    return axios.put(`${API_PATH}/user/update`, data, headers);
  },
  updateProfile(data, headers) {
    return axios.put(`${API_PATH}/user/profile-update`, data, headers);
  },
  /////////// END USERS /////////
  /* Roles API Start */
  getAllRoles(headers) {
    return axios.get(`${API_PATH}/role/getAll`, headers);
  },
  createRole(data, headers) {
    return axios.post(`${API_PATH}/role/create`, data, headers);
  },
  editRole(data) {
    return axios.post(`${API_PATH}/role/edit`, data);
  },
  deleteRole(data, headers) {
    return axios.post(`${API_PATH}/role/delete`, data, headers);
  },

  /* Roles API End */

  /////// START LOCATIONS/////////////
  getAllLocations(headers) {
    return axios.get(`${API_PATH}/location/getAllLocations`, headers);
  },
  createLocation(data, headers) {
    return axios.post(`${API_PATH}/location/register`, data, headers);
  },
  deleteLocation(data, headers) {
    return axios.post(`${API_PATH}/location/delete`, data, headers);
  },
  editLocation(data, headers) {
    return axios.put(`${API_PATH}/location/edit`, data, headers);
  },
  assignSplits(data, headers) {
    return axios.put(`${API_PATH}/location/assignSplits`, data, headers);
  },
  removeSplit(data, headers) {
    return axios.post(`${API_PATH}/location/removeSplit`, data, headers);
  },
  /////////// END LOCATIONS///////

  /////////// START REPORT ///////////////////
  createReport(data, headers) {
    return axios.post(`${API_PATH}/report/create`, data, headers);
  },
  getAllReport(headers) {
    return axios.get(`${API_PATH}/report/getAll`, headers);
  },
  deleteReport(data, headers) {
    return axios.post(`${API_PATH}/report/delete`, data, headers);
  },
  generateNow(data, headers) {
    return axios.post(`${API_PATH}/liveReport/generateNow`, data, headers);
  },
  //////////////// END REPORT  ////////////////

  /////////// START ENTITY ///////////////////
  createEntity(data, headers) {
    return axios.post(`${API_PATH}/entity/create`, data, headers);
  },
  getEntitys(headers) {
    return axios.get(`${API_PATH}/entity/getList`, headers);
  },
  getEntitiesLocationWise(headers) {
    return axios.get(`${API_PATH}/entity/getEntityByLocations`, headers);
  },
  deleteEntity(data, headers) {
    return axios.post(`${API_PATH}/entity/delete`, data, headers);
  },
  updateEntity(data, headers) {
    return axios.post(`${API_PATH}/entity/update`, data, headers);
  },
  // getEntityByUserId(data) {
  //   return axios.post(`${API_PATH}/entity/getEntityByUserId`, data);
  // },

  //////////////// END ENTITY  ////////////////

  ////////////////////////////////////////////////////
  ////////////////// Reconciliation/////////////////
  fileUpload(data, headers) {
    return axios.post(`${API_PATH}/reconciliation/fileUpload`, data, headers);
  },
  reconciliation(data, headers) {
    return axios.post(
      `${API_PATH}/reconciliation/reconciliation`,
      data,
      headers
    );
  },
  ////////////////// HISTORY/////////////////
  getHistory(data, headers) {
    return axios.get(
      `${API_PATH}/history/getList/${data.count}/${data.filterHistory}`,
      headers
    );
  },

  ///////////////////// FORMULA ///////////////////////

  createFormula(data, headers) {
    return axios.post(`${API_PATH}/formula/create`, data, headers);
  },
  updateFormula(data, headers) {
    return axios.post(`${API_PATH}/formula/update`, data, headers);
  },
  assignLocations(data, headers) {
    return axios.post(`${API_PATH}/formula/assignLocations`, data, headers);
  },
  deleteFormulaLocation(data, headers) {
    return axios.post(`${API_PATH}/formula/deleteLocation`, data, headers);
  },
  getFormula(headers) {
    return axios.get(`${API_PATH}/formula/getList`, headers);
  },
  deleteFormula(data, headers) {
    return axios.post(`${API_PATH}/formula/delete`, data, headers);
  },
  calculateFormula(data, headers) {
    return axios.post(`${API_PATH}/formula/calculate`, data, headers);
  },
};

//deleteFormulaLocation
