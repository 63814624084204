import API from "../utils/API";
import {
  GET_ERRORS,
  CLEAR_ERRORS,
  ALL_HISTORY_DATA,
  GET_HISTORY_FAIL,
  ALL_HISTORY_DATA_SUCCESS,
} from "./types";
import { getTokenInfo } from "./authActions";
// action to handle errors
export const getErrors = (errorData) => {
  return {
    type: GET_ERRORS,
    payload: errorData,
  };
};
// Action to clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

// export const getHistory = (count, filterHistory, values) => async (dispatch, getState) => {
//   try {
//     const headers = getTokenInfo(getState);

//     headers.headers["startDate"] = values.startDate;
//     headers.headers["endDate"] = values.endDate;

//     dispatch({
//       type: ALL_HISTORY_DATA,
//     });
//     // send request to server side to file upload on aws
//     const response = await API.getHistory(count, filterHistory, headers);
//     // dispatch to reducer
//     dispatch({
//       type: ALL_HISTORY_DATA_SUCCESS,
//       payload: response
//     });
//   } catch (err) {
//     // dispatch get error Action
//     dispatch(getErrors(err.response.data));
//     // dispatch file upload failed
//     dispatch({
//       type: GET_HISTORY_FAIL,
//     });
//   }
// };

export const getHistory = (data) => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to file upload on aws
    const response = await API.getHistory(data, headers);
    // dispatch to reducer
    dispatch({
      type: ALL_HISTORY_DATA,
      payload: response,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch file upload failed
    dispatch({
      type: GET_HISTORY_FAIL,
    });
  }
};
