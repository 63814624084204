import React, { useEffect } from "react";
import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import brandLogo from "../../images/SettleIt.svg";
import searchIocn from "../../images/serach-icon.svg";

import Modal from "../../components/Modal";
import SideNav from "../../components/NavBar";
import ConfirmDialog from "../../components/ConfirmDialog";
import { deleteUser } from "../../actions/userAction";
import { EventEmitter } from "../../components/Common/EventEmitter";
import { getAllUsers } from "../../actions/userAction";

import ListUsers from "./list";

export const User = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [confirmDialogShow, setConfirmDialogShow] = React.useState(false);
  const [locationsModal, setLocationsModal] = React.useState(false);
  const [locations, setLocations] = React.useState([]);
  const [user, setUser] = React.useState(null);
  // const [allUser, setAllUser] = React.useState([]);
  const [searchData, setSearchData] = React.useState([]);
  const [userId, setUserId] = React.useState("");
  const dispatch = useDispatch();

  const [title, setTitle] = React.useState("Create User");
  ///////////// GET USERS/////////
  const { loading } = useSelector((state) => state.user);
  let { users } = useSelector((state) => state.user);
  const userData = useSelector((state) => state.auth.userData);
  const permissions = get(userData, "role.modules", []);
  const allUsers = useSelector((state) => state.user.users);
  const role = get(userData, "role.title", "");
  useEffect(() => {
    dispatch(getAllUsers());
  }, [loading]);

  /////////// END GET USERS /////////
  const handleModel = (row) => {
    setModalShow(true);
    setTitle("Edit User");
    setUser(row);
  };
  const handleLocationModel = (locations) => {
    setLocationsModal(true);
    setLocations(locations);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    onSearch(value);
  };
  const onSearch = (value) => {
    // const newData = allUser.filter((n) => {
    //   return (
    //     new RegExp(value).test(n.fullName) || new RegExp(value).test(n.email)
    //   );
    // });
    const newData = allUsers.filter(
      (user) =>
        user.fullName.toLowerCase().includes(value) ||
        user.email.toLowerCase().includes(value)
    );
    setSearchData(newData);
  };
  const handleDelete = (id) => {
    setConfirmDialogShow(true);
    setUserId(id);
  };

  const onDelete = () => {
    dispatch(deleteUser({ _id: userId })).then((d) => {
      EventEmitter.dispatch("message", "Success!");
      EventEmitter.dispatch("close");
      setConfirmDialogShow(false);
    });
  };
  return (
    <>
      <section className="DashboardMainWrapper">
        <SideNav />
        <div className="DashboardWrapper">
          <div className="brandLogo">
            <img src={brandLogo} />
          </div>
          <div className="dashboardHeader">
            <h4>
              Users<span>/ {users.length}</span>{" "}
            </h4>
            <div className="RightBlock">
              <div className="searchBlock">
                <img src={searchIocn} />
                <input
                  type="search"
                  placeholder="Search..."
                  onChange={handleChange}
                />
              </div>
              <Button
                type="btn"
                className="Bluebtn"
                onClick={() => setModalShow(true)}
              >
                Add User
              </Button>
              {modalShow && role !== "superAdmin" && (
                <Modal
                  name="user"
                  open={modalShow}
                  title={title}
                  user={user}
                  setClose={() => setModalShow(false)}
                />
              )}
              {modalShow && role === "superAdmin" && (
                <Modal
                  name="admin"
                  open={modalShow}
                  title={title}
                  user={user}
                  setClose={() => {
                    setUser("");
                    setModalShow(false);
                  }}
                />
              )}
            </div>
          </div>
          <ListUsers
            handleModel={handleModel}
            handleLocationModel={handleLocationModel}
            handleDelete={handleDelete}
            // handleUser={handleUser}
            users={searchData.length ? searchData : users}
            role={role}
          />
          {locationsModal && (
            <Modal
              name="user-locations"
              open={locationsModal}
              // title={title}
              locations={locations}
              setClose={() => setLocationsModal(false)}
            />
          )}
          {confirmDialogShow && (
            <ConfirmDialog
              open={confirmDialogShow}
              // title={title}
              locations={locations}
              onDelete={onDelete}
              setClose={() => setConfirmDialogShow(false)}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default User;
