import API from "../utils/API";
import {
  GET_ERRORS,
  CLEAR_ERRORS,
  FILE_UPLOAD,
  FILE_UPLOAD_FAIL,
  RECONCILIATION,
  RECONCILIATION_FAIL,
} from "./types";
import { getTokenInfo } from "./authActions";
// action to handle errors
export const getErrors = (errorData) => {
  return {
    type: GET_ERRORS,
    payload: errorData,
  };
};
// Action to clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export const fileUpload = (data) => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to file upload on aws
    headers.headers["Content-type"] = "multipart/form-data";
    const response = await API.fileUpload(data, headers);
    // dispatch to reducer
    dispatch({
      type: FILE_UPLOAD,
      payload: response,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch file upload failed
    dispatch({
      type: FILE_UPLOAD_FAIL,
    });
  }
};
export const Reconciliation = (data) => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to file upload on aws
    const response = await API.reconciliation(data, headers);
    // dispatch to reducer
    dispatch({
      type: RECONCILIATION,
      payload: response,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch file upload failed
    dispatch({
      type: RECONCILIATION_FAIL,
    });
  }
};
