import React, { useState, useEffect } from "react";
import { get } from "lodash";
import * as yup from "yup";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";

import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import { EventEmitter } from "../../components/Common/EventEmitter";

/////////////////ACTIONS///////////////////
import { getAllRoles } from "../../actions/role";
import {
  registerUser,
  registerAdmin,
  clearAuth,
  updateAdmin,
} from "../../actions/authActions";

import { getAllLocations } from "../../actions/locations";
import { updateUser } from "../../actions/userAction";
import { getAllUsers, clearErrors } from "../../actions/userAction";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Schema = Yup.object().shape({
  fullName: Yup.string()
    .min(2, "Too Short!")
    // .max(100, "Too Long!")
    .required("Required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  mobile: yup.number("Enter your mobile").optional("Mobile is required"),
  organizationName: yup.string().optional(),
  //   locations: yup.array("Select locations").required("Locations is required"),
  //   role: yup.string("Select user role").required("Role is required"),
});

export default function CreateAdmin(props) {
  const dispatch = useDispatch();
  //////////GET ROLES AND LOCATIONS///////////
  const { locations } = useSelector((state) => state.location);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const userData = useSelector((state) => state.auth.userData);
  const role = get(userData, "role.title", "");

  const { user } = props;

  const formik = useFormik({
    initialValues: {
      email: user !== null ? user.email : "",
      fullName: user !== null ? user.fullName : "",
      mobile: user !== null ? user.mobile : "",
      organizationName: user !== null ? user.organizationName : "",
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      setIsSubmitting(true);
      if (user) {
        values.userId = user._id;
        dispatch(updateAdmin(values)).then((d) => {
          EventEmitter.dispatch("message", "Success!");
          EventEmitter.dispatch("close");
          dispatch(getAllUsers());
          setTimeout(() => {
            setIsSubmitting(false);
            props.setClose(false);
            dispatch(clearAuth());
          }, 2000);
        });
      } else {
        dispatch(registerAdmin(values));
        // .then((d) => {
        //   console.log(d);
        //   EventEmitter.dispatch("message", "Success!");
        //   EventEmitter.dispatch("close");
        //   dispatch(getAllUsers());
        //   setTimeout(() => {
        //     setIsSubmitting(false);
        //     props.setClose(false);
        //     dispatch(clearAuth());
        //   }, 2000);
        // });
      }
    },
  });

  ///////////////////////////////
  const error = useSelector((state) => state.errors);
  const { registerRes } = useSelector((state) => state.auth);

  console.log("registerRes", registerRes);

  useEffect(() => {
    if (error.message) {
      setErrorMessage(error.message);
      setIsSubmitting(false);
      dispatch(clearErrors());
    }
    if (registerRes && registerRes.success) {
      EventEmitter.dispatch("message", "Success!");
      EventEmitter.dispatch("close");
      dispatch(getAllUsers());
      setTimeout(() => {
        setIsSubmitting(false);
        props.setClose(false);
        dispatch(clearAuth());
      }, 2000);
    }
    if (registerRes && !registerRes.success) {
      EventEmitter.dispatch("message", registerRes.message);
      EventEmitter.dispatch("close");
      dispatch(getAllUsers());
      setErrorMessage(registerRes.message);
      dispatch(clearErrors());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, registerRes]);

  return (
    <>
      <div className="add-user-modal">
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {props.title}
        </Typography>

        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          // noValidate
          // autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <TextField
            variant="outlined"
            name="fullName"
            label="Name"
            type="text"
            value={formik.values.fullName}
            onChange={formik.handleChange}
            error={formik.touched.fullName && Boolean(formik.errors.fullName)}
            helperText={formik.touched.fullName && formik.errors.fullName}
          />
          <TextField
            variant="outlined"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <TextField
            variant="outlined"
            name="mobile"
            label="Mobile"
            type="mobile"
            value={formik.values.mobile}
            onChange={formik.handleChange}
            error={formik.touched.mobile && Boolean(formik.errors.mobile)}
            helperText={formik.touched.mobile && formik.errors.mobile}
          />

          <TextField
            variant="outlined"
            name="organizationName"
            label="Organization Name"
            type="text"
            value={formik.values.organizationName}
            onChange={formik.handleChange}
            error={
              formik.touched.organizationName &&
              Boolean(formik.errors.organizationName)
            }
            helperText={
              formik.touched.organizationName && formik.errors.organizationName
            }
          />

          {!isSubmitting ? (
            <Typography style={{ color: "red" }} variant="h6">
              {errorMessage}
            </Typography>
          ) : (
            ""
          )}
          <div className="text-center mt-2">
            <Button
              className="Bluebtn"
              variant="contained"
              fullWidth
              type="submit"
              disabled={isSubmitting}
            >
              Proceed
            </Button>
          </div>
        </Box>
      </div>
    </>
  );
}
