import { get } from "lodash";
import API from "../utils/API";
import {
  REGISTER_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REGISTER_FAIL,
  LOGIN_FAIL,
  GET_ERRORS,
  CLEAR_ERRORS,
  LOAD_USER,
  LOAD_USER_FAIL,
  CLEAR_AUTH,
} from "./types";
import history from "../history";

// action to handle errors
export const getErrors = (errorData) => {
  return {
    type: GET_ERRORS,
    payload: errorData,
  };
};
// Action to clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
export const clearAuth = () => {
  return {
    type: CLEAR_AUTH,
  };
};
// Action to register new users
export const registerUser = (data) => async (dispatch, getState) => {
  try {
    // send request to server side to register user
    const headers = getTokenInfo(getState);
    const response = await API.register(data, headers);
    // dispatch to reducer
    dispatch({
      type: REGISTER_SUCCESS,
      payload: response.data,
    });
    // redirect to page
    // history.push("/users");
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch register fail
    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

export const registerAdmin = (data) => async (dispatch, getState) => {
  try {
    // send request to server side to register user
    const headers = getTokenInfo(getState);
    const response = await API.registerAdmin(data, headers);
    // dispatch to reducer
    dispatch({
      type: REGISTER_SUCCESS,
      payload: response.data,
    });
    // redirect to page
    // history.push("/users");
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch register fail
    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

export const updateAdmin = (data) => async (dispatch, getState) => {
  try {
    // send request to server side to register user
    const headers = getTokenInfo(getState);
    const response = await API.updateAdminDetails(data, headers);
    // dispatch to reducer
    dispatch({
      type: REGISTER_SUCCESS,
      payload: response.data,
    });
    // redirect to page
    // history.push("/users");
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch register fail
    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

// Action to login users
export const loginUser = (data) => async (dispatch) => {
  try {
    // send request to server side to login user
    const response = await API.login(data);
    // dispatch to reducer
    dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data,
    });

    // redirect to page
    const role = get(response, "data.role.title", "admin");
    if (role === "superAdmin") {
      history.push("/users");
    } else {
      history.push("/reconciliation");
    }
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch login fail
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const impersonateUser = (data) => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    // send request to server side to login user
    const response = await API.impersonateUser(data, headers);
    // dispatch to reducer
    dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data,
    });

    // redirect to page
    window.location.href = "/profile";
    // const role = response.data.role;
    // history.push("/reconciliation");
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response.data));
    // dispatch login fail
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// function to configure token to be used on any server request using the server side "auth" middleware
export const getTokenInfo = (getState) => {
  const token = getState().auth.token;
  const headerConfig = {
    headers: {
      "Content-type": "application/json",
    },
  };
  if (token) {
    headerConfig.headers["token"] = token;
  }

  return headerConfig;
};
// Action to logout user
export const LogoutUser = () => {
  // redirect to page
  history.push("/");
  // dispatch logout success
  return {
    type: LOGOUT_SUCCESS,
  };
};
// Action to register new users

export const loadUser = () => async (dispatch, getState) => {
  try {
    const headers = getTokenInfo(getState);
    const response = await API.loadUser(headers);

    // dispatch to reducer
    dispatch({
      type: LOAD_USER,
      payload: response.data,
    });
  } catch (err) {
    // dispatch get error Action
    dispatch(getErrors(err.response));
    // dispatch fetch fail
    dispatch({
      type: LOAD_USER_FAIL,
    });
  }
};
